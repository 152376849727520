import { generatePath, Link } from 'react-router-dom';

import { routes } from '~/utils';

interface Props {
  runAfterSyncs: {
    __typename?: 'BulkSync' | 'Sync';
    id: string;
    name: string;
    targetConnection?: {
      name: string;
    };
  }[];
}

export function RunAfterSyncDisplay(props: Props) {
  if (props.runAfterSyncs.length === 1) {
    const sync = props.runAfterSyncs[0];
    const syncText = sync.targetConnection?.name
      ? `${sync.targetConnection.name}: ${sync.name}`
      : sync.name;

    return (
      <p>
        Sync after{' '}
        {sync.__typename ? (
          <Link
            to={generatePath(
              sync.__typename === 'Sync' ? routes.syncStatus : routes.bulkSyncStatus,
              {
                id: sync.id
              }
            )}
            className="link break-all"
          >
            {syncText}
          </Link>
        ) : (
          <span className="break-all">{syncText}</span>
        )}
      </p>
    );
  }

  return (
    <div className="space-y-1">
      <p>Sync after:</p>
      <ul className="list-outside list-disc space-y-1 pl-5">
        {props.runAfterSyncs.map(sync => {
          const syncText = sync.targetConnection?.name
            ? `${sync.targetConnection.name}: ${sync.name}`
            : sync.name;

          return (
            <li key={sync.id} className="break-all">
              {sync.__typename ? (
                <Link
                  to={generatePath(
                    sync.__typename === 'Sync' ? routes.syncStatus : routes.bulkSyncStatus,
                    { id: sync.id }
                  )}
                  className="link"
                >
                  {syncText}
                </Link>
              ) : (
                syncText
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
