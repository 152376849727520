import { useQuery } from '@apollo/client';
import { JSONSchema4 } from 'json-schema';
import * as React from 'react';
import { Controller, useController, useFormContext, useWatch } from 'react-hook-form';
import { v4 as uuid } from 'uuid';

import {
  Checkbox,
  ComboboxProps,
  ConnectionSelect,
  FormEnumSelect,
  JSONSchemaForm,
  Label,
  MyCombobox,
  MyInput
} from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import AutoComplete, { AutoCompleteOption } from '~/components/v2/inputs/AutoComplete';
import {
  Action,
  BulkDestinationDocument,
  BulkSourceQueryVariables,
  ConnectionsDocument,
  Operation
} from '~/generated/graphql';
import { useBannerDispatch, useSanitizeIdText } from '~/hooks';
import { BulkSyncForm, Selectable, filterConnections, getSchemaAsList } from '~/utils';
import { StageCard } from '../../syncs/sync-config';

export type StageBulkTargetProps = {
  id: string | undefined;
  getSchemas: (args: BulkSourceQueryVariables) => void;
  setIsDirty: React.Dispatch<React.SetStateAction<boolean>>;
  completeModeStage: () => void;
};

export const StageBulkTarget = ({ setIsDirty, id, ...props }: StageBulkTargetProps) => {
  const dispatchBanner = useBannerDispatch();
  const { control, setValue } = useFormContext<BulkSyncForm>();
  const { data: connectionsData, loading } = useQuery(ConnectionsDocument);

  const source = useWatch({ control, name: 'source' });
  const destination = useWatch({ control, name: 'destination' });

  const destConnection = useWatch({ control, name: 'destination.connection' });
  const destConfiguration = useWatch({ control, name: 'destinationConfiguration' });

  const schema = React.useMemo(() => {
    return getSchemaAsList(destination?.configurationSchema, 'destinationConfiguration');
  }, [destination?.configurationSchema]);

  useQuery(BulkDestinationDocument, {
    fetchPolicy: 'no-cache',
    skip: !destConnection?.id,
    variables: {
      connectionId: destConnection?.id,
      configuration: destConfiguration
    },
    onCompleted: data => {
      if (!data || !data.bulkDestinationForConnection) {
        return;
      }
      const destination = data.bulkDestinationForConnection;
      setValue('destination', destination);
      if (destination.supportedModes.length === 1) {
        // default to the sole supported mode
        setValue('mode', destination.supportedModes[0].mode);
        props.completeModeStage();
      }
    },
    onError: error => {
      if (destConnection?.id) {
        dispatchBanner({
          type: 'show',
          payload: { message: error, wrapper: 'px-3 pt-3 max-w-5xl mx-auto' }
        });
      }
    }
  });

  return (
    <StageCard
      step={1}
      header="Choose source and destination"
      className="grid grid-cols-2 gap-x-4 px-6"
    >
      <label className="label col-start-1 row-start-1">Source system</label>
      <Controller
        control={control}
        name="source.connection"
        render={({ field }) => (
          <ConnectionSelect
            className="col-start-1 row-start-2"
            isLoading={loading}
            autoFocus={!id}
            options={filterConnections(connectionsData, Operation.BulkSyncSource, Action.Query)}
            value={field.value}
            onChange={connection => {
              field.onChange(connection);
              void props.getSchemas({ connectionId: connection.id, continuation: uuid() });
              setIsDirty(true);
            }}
          />
        )}
      />
      {source && (
        <>
          <label className="label col-start-2 row-start-1">Destination system</label>
          <Controller
            control={control}
            name="destination.connection"
            render={({ field }) => (
              <ConnectionSelect
                className="col-start-2 row-start-2"
                isLoading={loading}
                options={filterConnections(
                  connectionsData,
                  Operation.BulkSyncDestination,
                  Action.SyncTo
                ).filter(
                  connection =>
                    !connection?.type?.operations?.includes(Operation.RequiresPermalink) ||
                    source?.connection?.type?.operations?.includes(Operation.ProvidesPermalink)
                )}
                value={field.value}
                onChange={connection => {
                  if (connection.id === destConnection?.id) {
                    return;
                  }
                  setValue('destination', null);
                  setValue('destinationConfiguration', null);
                  field.onChange(connection);
                  setIsDirty(true);
                }}
              />
            )}
          />
          {destination?.configurationForm ? (
            <div className="col-start-2 row-start-3 mt-2">
              <JSONSchemaForm
                schema={destination.configurationForm.jsonschema}
                uiSchema={destination.configurationForm.uischema}
                formData={destConfiguration}
                onChange={data => setValue('destinationConfiguration', data)}
              />
            </div>
          ) : (
            schema && (
              <div className="col-start-2 row-start-3 mt-2">
                {destConfig({
                  destination: destination,
                  source: source
                })}
              </div>
            )
          )}
        </>
      )}
    </StageCard>
  );
};

const destConfig = ({
  destination,
  source
}: {
  destination: BulkSyncForm['destination'];
  source: BulkSyncForm['source'];
}) => {
  switch (destination.defaultConfiguration.__typename) {
    case 'AirtableDestinationConfiguration':
      return <AirtableDestinationConfig />;
    case 'BlobDestinationConfiguration':
      return <ConfigSelect configSchema={destination.configurationSchema} />;
    case 'BigQueryDestinationConfiguration':
      return (
        <BigQueryDestConfig
          destConfigurationSchema={destination.configurationSchema}
          destConnectionID={destination.connection.id}
          source={source}
        />
      );
    case 'DatabaseDestinationConfiguration':
      return (
        <WarehouseDestConfig
          destConfigurationSchema={destination.configurationSchema}
          destConnectionID={destination.connection.id}
          source={source}
        />
      );
    case 'DatabricksDestinationConfiguration':
      return (
        <DatabricksDestConfig
          destConfigurationSchema={destination.configurationSchema}
          destConnectionID={destination.connection.id}
          source={source}
        />
      );
    case 'GleanDestinationConfiguration':
      return <GleanDestinationConfig />;
    case 'WarehouseDestinationConfiguration':
      return (
        <WarehouseDestConfig
          destConfigurationSchema={destination.configurationSchema}
          destConnectionID={destination.connection.id}
          source={source}
        />
      );
  }
  return <></>;
};

const AirtableDestinationConfig = () => {
  const { register } = useFormContext<BulkSyncForm>();

  return (
    <div>
      <MyInput {...register('destinationConfiguration.base')} label={'Base'} />
    </div>
  );
};

const GleanDestinationConfig = () => {
  const { register } = useFormContext<BulkSyncForm>();

  return (
    <div>
      <MyInput {...register('destinationConfiguration.datasource')} label={'Datasource'} />
    </div>
  );
};

type WarehouseProps = {
  destConfigurationSchema: JSONSchema4;
  destConnectionID: string;
  source: BulkSyncForm['source'];
};

const BigQueryDestConfig = ({
  destConfigurationSchema,
  destConnectionID,
  source
}: WarehouseProps) => {
  const { register, control } = useFormContext<BulkSyncForm>();
  const textFieldDisabled = useWatch({ control, name: 'destinationConfiguration.mirrorSchemas' });
  const schemaDefs = getSchemaAsList(destConfigurationSchema, 'destinationConfiguration');
  const schema = schemaDefs.find(item => item.name == 'destinationConfiguration.dataset');

  return (
    <div className="flex flex-col space-y-2">
      <div>
        {textFieldDisabled ? (
          <MyInput
            disabled={true}
            value="Multiple schemas"
            label={schema.title}
            name={schema.title}
          />
        ) : (
          <SanitizerInput
            destConnectionId={destConnectionID}
            item={{ ...schema, default: source.connection.name }}
          />
        )}
      </div>
      {source?.properties?.supportsNamespaces && (
        <Checkbox
          {...register('destinationConfiguration.mirrorSchemas')}
          defaultChecked={textFieldDisabled}
          label={'Mirror source schemas'}
        />
      )}
    </div>
  );
};
const DatabricksDestConfig = ({
  destConnectionID,
  source,
  destConfigurationSchema
}: WarehouseProps) => {
  const { register, control } = useFormContext<BulkSyncForm>();
  const textFieldDisabled = useWatch({ control, name: 'destinationConfiguration.mirrorSchemas' });
  const schemaDefs = getSchemaAsList(destConfigurationSchema, 'destinationConfiguration');

  const catalog = schemaDefs.find(item => item.name === 'destinationConfiguration.catalog');
  const schema = schemaDefs.find(item => item.name === 'destinationConfiguration.schema');
  const location = schemaDefs.find(
    item => item.name === 'destinationConfiguration.externalLocationName'
  );

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <SanitizerInput
          destConnectionId={destConnectionID}
          item={catalog}
          placeholder="Default catalog"
          disabled={catalog.readonly}
        />
      </div>
      <div>
        {textFieldDisabled ? (
          <MyInput
            disabled={true}
            value="Multiple schemas"
            label={schema.title}
            name={schema.title}
          />
        ) : (
          <SanitizerInput
            destConnectionId={destConnectionID}
            item={{ ...schema, default: source.connection.name }}
          />
        )}
      </div>
      {source?.properties?.supportsNamespaces && (
        <Checkbox
          {...register('destinationConfiguration.mirrorSchemas')}
          defaultChecked={textFieldDisabled}
          label={'Mirror source schemas'}
        />
      )}
      {location && (
        <div>
          <FormEnumSelect item={location} noAutoSelect={true} readOnly={location['readonly']} />
        </div>
      )}
    </div>
  );
};

const WarehouseDestConfig = ({
  destConfigurationSchema,
  destConnectionID,
  source
}: WarehouseProps) => {
  const { register, control } = useFormContext<BulkSyncForm>();
  const textFieldDisabled = useWatch({ control, name: 'destinationConfiguration.mirrorSchemas' });
  const schemaDefs = getSchemaAsList(destConfigurationSchema, 'destinationConfiguration');

  const schema = schemaDefs.find(item => item.name == 'destinationConfiguration.schema');

  return (
    <div className="flex flex-col space-y-2">
      <div>
        {textFieldDisabled ? (
          <MyInput
            disabled={true}
            value="Multiple schemas"
            label={schema.title}
            name={schema.title}
          />
        ) : (
          <SanitizerInput
            destConnectionId={destConnectionID}
            item={{ ...schema, default: source.connection.name }}
          />
        )}
      </div>
      {source?.properties?.supportsNamespaces && (
        <Checkbox
          {...register('destinationConfiguration.mirrorSchemas')}
          defaultChecked={textFieldDisabled}
          label={'Mirror source schemas'}
        />
      )}
    </div>
  );
};

type SanitizerInputProps = {
  destConnectionId: string | undefined;
  item: JSONSchema4 | undefined;
  placeholder?: string;
  disabled?: boolean;
};

const SanitizerInput = ({ destConnectionId, item, placeholder, disabled }: SanitizerInputProps) => {
  const { setValue, watch } = useFormContext<BulkSyncForm>();
  const { sanitizeIdText, sanitizeIdTextLoading } = useSanitizeIdText();
  const selectedItem = watch(item?.name);

  const prevTextRef = React.useRef<string>('');

  React.useEffect(() => {
    const handleDefault = async (defaultVal: string | undefined) => {
      if (!destConnectionId || !defaultVal || !item?.name) {
        return;
      }
      const safeName = await sanitizeIdText(destConnectionId, defaultVal);
      if (!safeName) {
        return;
      }
      prevTextRef.current = safeName;
      setValue(item.name, safeName);
    };

    if (item?.default && !selectedItem) {
      void handleDefault(item?.default as string | undefined);
    }
  }, [item?.default, destConnectionId, selectedItem, item?.name, sanitizeIdText, setValue]);

  if (!item) {
    return null;
  }

  const onBlur = async (selected: AutoCompleteOption) => {
    if (!destConnectionId || !selected.value || selected.value === prevTextRef.current) {
      return;
    }
    const safeName = await sanitizeIdText(destConnectionId, selected.value);
    if (!safeName) {
      return;
    }
    prevTextRef.current = safeName;
    setValue(item.name, safeName);
  };

  return (
    <div className="relative">
      <AutoComplete
        variant="outlined"
        label={item.title}
        disabled={sanitizeIdTextLoading || disabled}
        onChange={onBlur}
        options={[{ label: selectedItem, value: selectedItem }]}
        allowNew={true}
        value={{ label: selectedItem, value: selectedItem }}
        showButton={false}
        placeholder={placeholder}
        updateOnBlur={true}
      />
      {sanitizeIdTextLoading && (
        <div className="absolute bottom-2.5 right-2 flex items-center">
          <LoadingDots />
        </div>
      )}
    </div>
  );
};

type ConfigSelectProps = Omit<ComboboxProps, 'onChange'> & {
  configSchema: JSONSchema4;
  className?: string;
};

const ConfigSelect = ({ configSchema, className, ...props }: ConfigSelectProps) => {
  const schema = getSchemaAsList(configSchema, 'destinationConfiguration');
  const item = schema.find(i => 'enum' in i);
  const subfolder = schema.find(item => item.name === 'destinationConfiguration.subfolder');
  const { field: f } = useController({ name: item.name, shouldUnregister: true });

  const handleChange = (option: Selectable | null) => {
    if (!item.name || option == null) {
      return;
    }
    f.onChange(option.value);
  };

  React.useEffect(() => {
    if (f.value || !item.default) {
      return;
    }
    f.onChange(item.default);
  }, [f, item.default]);

  if (!item.enum || item.enum.length === 0) {
    return null;
  }

  const options =
    (item.enum as (string | Selectable)[])?.map(option => {
      if (typeof option === 'object') {
        return {
          label: option.label || option.value,
          value: option.value
        };
      }
      return { label: option, value: option };
    }) || [];

  const value = React.useMemo(
    () => options.find(option => option.value === f.value) || null,
    [options, f]
  );

  const { register } = useFormContext<BulkSyncForm>();
  return (
    <div className="flex flex-col space-y-2">
      <div>
        <MyInput
          name={subfolder.title}
          label={subfolder.title}
          placeholder="Subfolder (optional)"
          {...register('destinationConfiguration.subfolder')}
        />
      </div>
      <div className={className}>
        <div>
          <Label>{item.title}</Label>
        </div>
        <MyCombobox value={value} options={options} onChange={handleChange} {...props} />
      </div>
    </div>
  );
};
