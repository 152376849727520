
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "APIPagination": [
      "NextPagePagination",
      "OffsetPagination",
      "SequentialPagePagination",
      "TokenPagination",
      "Unconfigured"
    ],
    "ActivityEvent": [
      "ArrayObj",
      "BoolObj",
      "ConnectionChangeEvent",
      "FieldMappingChangeEvent",
      "FilterChangeEvent",
      "FloatObj",
      "IdentityChangeEvent",
      "IntObj",
      "NotificationChangeEvent",
      "OverrideChangeEvent",
      "PairObj",
      "RelationshipChangeEvent",
      "StringObj",
      "SyncScheduleChangeEvent",
      "ValueLabelEvent"
    ],
    "BulkDestinationConfiguration": [
      "AirtableDestinationConfiguration",
      "BigQueryDestinationConfiguration",
      "BlobDestinationConfiguration",
      "DatabaseDestinationConfiguration",
      "DatabricksDestinationConfiguration",
      "GleanDestinationConfiguration",
      "MySQLDestinationConfiguration",
      "NoBulkConfiguration",
      "WarehouseDestinationConfiguration"
    ],
    "BulkSchedule": [
      "ContinuousSchedule",
      "CustomSchedule",
      "DailySchedule",
      "HourlySchedule",
      "ManualSchedule",
      "MultiSchedule",
      "WeeklySchedule"
    ],
    "BulkSourceConfiguration": [
      "GongSourceConfiguration",
      "GoogleAdsSourceConfiguration",
      "NoConfiguration",
      "PostgresqlSourceConfiguration",
      "StripeSourceConfiguration"
    ],
    "BulkSyncSuccessOrConfirmation": [
      "BulkSync",
      "ConfirmationRequest",
      "SyncErrorResponse"
    ],
    "ConnectionUsedBy": [
      "BulkSync",
      "Fieldset",
      "IntegrationSchedule",
      "Sync"
    ],
    "DataArchitectureResponseOrContinuation": [
      "Continuation",
      "DataArchitectureResponse"
    ],
    "DeleteConnectionResponse": [
      "ConnectionDeletedSuccessResponse",
      "ConnectionErrorResponse"
    ],
    "DeleteSyncResponse": [
      "SyncDeletedSuccessResponse",
      "SyncErrorResponse"
    ],
    "ExploreStatusOrPoller": [
      "ExploreStatus",
      "Poller"
    ],
    "ExportSqlResultOrContinuation": [
      "Continuation",
      "ExportSqlResult"
    ],
    "FieldsetConfiguration": [
      "APIConfiguration",
      "AffinityConfiguration",
      "AirtableConfiguration",
      "ApolloEnrichmentConfiguration",
      "AscendConfiguration",
      "AthenaConfiguration",
      "AttioConfiguration",
      "BigQueryConfiguration",
      "BlobstoreConfiguration",
      "CSVConfiguration",
      "ChargebeeConfiguration",
      "ClariConfiguration",
      "CosmosDBConfiguration",
      "DataAccessConfiguration",
      "DatabricksConfiguration",
      "DataliteConfiguration",
      "DayforceConfiguration",
      "DelightedConfiguration",
      "DialpadConfiguration",
      "DynamoDBConfiguration",
      "FakeDataEnrichmentConfiguration",
      "FrontConfiguration",
      "GSheetsConfiguration",
      "GongConfiguration",
      "GoogleWorkspaceConfiguration",
      "HarmonicConfiguration",
      "HarmonicEnrichmentConfiguration",
      "HeronConfiguration",
      "HttpEnrichmentConfiguration",
      "HubspotConfiguration",
      "IntercomConfiguration",
      "IroncladConfiguration",
      "KlaviyoConfiguration",
      "KustomerConfiguration",
      "MailerCheckEnrichmentConfiguration",
      "MarketoConfiguration",
      "MondayConfiguration",
      "MongoDBConfiguration",
      "NetsuiteConfiguration",
      "NetsuiteOpenairConfiguration",
      "OutreachConfiguration",
      "PipedriveConfiguration",
      "PitchbookConfiguration",
      "PredictLeadsEnrichmentConfiguration",
      "QuickBooksConfiguration",
      "ReplyioConfiguration",
      "SQLConfiguration",
      "SalesforceConfiguration",
      "SalesloftConfiguration",
      "ScamalyticsConfiguration",
      "SeamAIConfiguration",
      "ShipbobConfiguration",
      "ShopifyConfiguration",
      "SmartleadConfiguration",
      "StatsigConfiguration",
      "StrackrConfiguration",
      "StripeConfiguration",
      "SurvicateConfiguration",
      "Unconfigured",
      "UserVoiceConfiguration",
      "VanillaConfiguration",
      "WarehouseConfiguration",
      "YotpoConfiguration",
      "ZendeskConfiguration",
      "ZoomInfoConfiguration",
      "ZoomInfoEnrichmentConfiguration"
    ],
    "FieldsetOrContinuation": [
      "Continuation",
      "Fieldset"
    ],
    "FilterField": [
      "ModelField",
      "TargetField"
    ],
    "ModelSuccessOrConfirmation": [
      "ConfirmationRequest",
      "Model"
    ],
    "QueryResultConnectionOrPoller": [
      "Poller",
      "QueryResultConnection"
    ],
    "QueryResultCountOrPoller": [
      "Poller",
      "QueryResultCount"
    ],
    "QueryResultOrPoller": [
      "Poller",
      "QueryResult"
    ],
    "Schedule": [
      "ContinuousSchedule",
      "CustomSchedule",
      "DBTCloudSchedule",
      "DailySchedule",
      "HourlySchedule",
      "ManualSchedule",
      "RunAfterSchedule",
      "WeeklySchedule"
    ],
    "SqlRunnerResultOrPoller": [
      "Poller",
      "SqlRunnerResult"
    ],
    "SyncRecordsOrContinuation": [
      "Continuation",
      "SyncRecords"
    ],
    "TagUsedBy": [
      "BulkSync",
      "Connection",
      "Fieldset",
      "Sync"
    ],
    "TargetObjectConfiguration": [
      "AffinityTargetConfiguration",
      "AttioTargetConfiguration",
      "BlobTargetConfiguration",
      "CustomerioTargetConfiguration",
      "DatabaseTargetConfiguration",
      "HubspotContactTargetConfiguration",
      "HubspotTargetConfiguration",
      "IntercomContactTargetConfiguration",
      "IntercomTargetConfiguration",
      "MarketoTargetConfiguration",
      "MondayTargetConfiguration",
      "MultiTargetConfiguration",
      "NoConfiguration",
      "SalesforceLeadTargetConfiguration",
      "SalesforceTargetConfiguration",
      "SnowflakeTargetConfiguration",
      "WebhookTargetConfiguration",
      "ZendeskTargetConfiguration"
    ]
  }
};
      export default result;
    