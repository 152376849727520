import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Confirming } from './Confirm';
import { Connect } from './Connect';
import { ConnectUsing } from './ConnectUsing';
import { Connected } from './Connected';
import { Failure } from './Failure';
import { Initializing } from './Initializing';
import { Layout } from './Layout';
import { useConnect } from './hooks';

export default function ConnectPage() {
  const params = useParams();
  const queryParameters = new URLSearchParams(location.search);
  const connectionId = queryParameters.get('connection') as string | undefined;
  const name = queryParameters.get('name') as string | undefined;
  const redirectUrl = queryParameters.get('redirect') as string | undefined;
  const whitelistString = queryParameters.get('whitelist');
  const whitelist = whitelistString?.length ? whitelistString.split(',') : [];
  const token = queryParameters.get('token') as string;
  const typeId = params['type'] as string | undefined;

  const connect = useConnect();
  const storageKey = `polytomic-connect-${token}`;
  useEffect(() => {
    const stored = JSON.parse(localStorage.getItem(storageKey) || '{}');
    if (stored?.token?.length && stored?.connection?.id?.length) {
      connect.restart(stored);
    } else {
      connect.initialize({
        connectionId,
        name,
        redirectUrl,
        token,
        typeId,
        whitelist
      });
    }
  }, []);

  useEffect(() => {
    if (connect.context?.token?.length) {
      localStorage.setItem(storageKey, JSON.stringify(connect.context));
    }
  }, [connect.context]);

  return (
    <Layout>
      {connect.value === 'failure' && <Failure {...connect.context} onClose={connect.cancel} />}
      {connect.value === 'initializing' && <Initializing />}
      {connect.value === 'selecting' && (
        <ConnectUsing {...connect.context} onSelected={connect.select} />
      )}
      {connect.value === 'confirming' && (
        <Confirming {...connect.context} onCancel={connect.cancel} onContinue={connect.confirm} />
      )}
      {(connect.value === 'connecting' || connect.value === 'saving') && (
        <Connect
          {...connect.context}
          isConnecting={connect.value === 'saving'}
          onCancel={connect.cancel}
          onConnect={connect.connect}
        />
      )}
      {connect.value === 'reviewing' && (
        <Connected {...connect.context} onDone={connect.complete} />
      )}
    </Layout>
  );
}
