import { useState, useEffect } from 'react';

interface EstimatedRowSizeOptions {
  breakpoint?: number;
  small?: number;
  large?: number;
}

export function useEstimatedRowSize({
  breakpoint = 1600,
  small = 48.2,
  large = 53
}: EstimatedRowSizeOptions = {}) {
  const [estimatedRowSize, setEstimatedRowSize] = useState(
    window.innerWidth >= breakpoint ? large : small
  );

  useEffect(() => {
    const updateEstimatedSize = () => {
      setEstimatedRowSize(window.innerWidth >= breakpoint ? large : small);
    };

    window.addEventListener('resize', updateEstimatedSize);
    return () => window.removeEventListener('resize', updateEstimatedSize);
  }, [breakpoint, small, large]);

  return estimatedRowSize;
}
