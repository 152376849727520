import * as React from 'react';
import { useFormContext } from 'react-hook-form';

import {
  DisabledSelect,
  EditPermission,
  MyCombobox,
  MyInput,
  SideBySide
} from '../../../components';
import { ConnectionTypeFragment } from '../../../generated/graphql';
import { getSchemaNormalized, isRequiredMsg, Selectable } from '../../../utils';
import { ConnectionFormValues, ConnectionWithoutType } from '../connection-config';
import { OauthButton } from '../connection-components';

const DIALPAD_AUTH_METHODS = {
  APIKEY: { label: 'API key', value: 'api_key' },
  OAUTH: { label: 'OAuth', value: 'oauth' }
};

interface Props {
  connection?: ConnectionWithoutType;
  connectionType: ConnectionTypeFragment;
  oauthLoading: boolean;
  saved: boolean;
  onSave: (force: boolean) => (data: ConnectionFormValues) => Promise<void>;
  setConnectionType: React.Dispatch<React.SetStateAction<ConnectionTypeFragment>>;
}

export function DialpadConnectionConfig(props: Props) {
  const { connection, connectionType, oauthLoading, saved, onSave, setConnectionType } = props;

  const { setValue, register, getValues, formState } = useFormContext<ConnectionFormValues>();
  const { errors } = formState;

  const [authMethod, setAuthMethod] = React.useState<Selectable>(() => {
    const auth = getValues('configuration.auth_method');
    return auth === 'oauth' ? DIALPAD_AUTH_METHODS.OAUTH : DIALPAD_AUTH_METHODS.APIKEY;
  });

  const fields = getSchemaNormalized(connectionType.configurationSchema);

  const handleAuthMethodChange = (option: Selectable | null) => {
    if (option) {
      setValue('configuration.auth_method', option.value, {
        shouldValidate: true,
        shouldDirty: true
      });
      setAuthMethod(option);
      setConnectionType({ ...connectionType, useOAuth: option.value === 'oauth' });
    }
  };

  return (
    <>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Authentication method">
        <EditPermission
          fallback={<DisabledSelect valueLabel={authMethod?.label} className="max-w-xs" />}
        >
          <MyCombobox
            className="max-w-xs"
            value={authMethod}
            options={Object.values(DIALPAD_AUTH_METHODS)}
            onChange={handleAuthMethodChange}
          />
        </EditPermission>
      </SideBySide>
      <SideBySide hasSectionWrap styles="space-y-3" heading="Options">
        {authMethod?.value === DIALPAD_AUTH_METHODS.APIKEY.value && (
          <div className="max-w-xs">
            <EditPermission>
              <MyInput
                {...register('configuration.api_key', {
                  required: isRequiredMsg(fields?.['api_key']?.title)
                })}
                label={fields?.['api_key']?.title}
                description={fields?.['api_key']?.description}
                errors={errors}
              />
            </EditPermission>
          </div>
        )}
        {authMethod?.value === DIALPAD_AUTH_METHODS.OAUTH.value && (
          <div className="max-w-xs">
            <OauthButton
              isConnected={
                saved ||
                (!!connection?.saved && authMethod?.value === DIALPAD_AUTH_METHODS.OAUTH.value)
              }
              oauthLoading={oauthLoading}
              connectionTypeName={connectionType.name}
              onSave={onSave}
            />
          </div>
        )}
      </SideBySide>
    </>
  );
}
