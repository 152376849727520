/* eslint-disable no-nested-ternary */
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { Button, Icon, LinkButton } from '~/components';
import LoadingDots from '~/components/v2/feedback/LoadingDots';
import {
  DataTableVirtual,
  Status,
  type ColumnDef,
  type PaginatedQueryParams
} from '~/components/v3';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '~/components/v3/DropdownMenu';
import {
  ExecutionStatus,
  ExecutionTrigger,
  Operation,
  StartSyncDocument,
  SyncDocument,
  SyncExecutionFragment,
  SyncExecutionsDocument,
  SyncExecutionsQuery,
  SyncFragment,
  SyncMode,
  SyncStatusDocument
} from '~/generated/graphql';
import { useBannerDispatch, useEstimatedRowSize } from '~/hooks';
import { useConfiguration } from '~/hooks/configuration';
import { cn } from '~/lib/utils';
import {
  ExecutionRecordType,
  ExecutionRecordsDialog,
  SYNC_FAILURE_ERROR_SUFFIX,
  capsFirst,
  emptyCell,
  getHistoryFilters,
  getLongLocalTime,
  prepareRecordDisplay,
  recordDialogHeading
} from '~/utils';
import { SyncExecutionRecordsPreview } from '../sync-execution-records-preview';
import { HistoryFilters } from './history-filters';
import { useSyncHistory } from './use-sync-history';
import { WebhookSyncRequests } from './webhook-sync-requests';
import { isEmpty } from 'lodash';

interface QueryData {
  pages: {
    edges: {
      node: unknown;
      cursor: string;
    }[];
  }[];
  pageParams: unknown;
}

const updateQueryData = (data: SyncExecutionsQuery) => (queryData: QueryData) => {
  const newEdges =
    data?.syncExecutions?.edges?.filter(
      (edge, index) =>
        index <= 24 && !queryData?.pages?.[0]?.edges?.find(e => e.cursor === edge.cursor)
    ) ?? [];
  const updatedEdges = queryData?.pages?.[0]?.edges?.map(edge => {
    const updated = data.syncExecutions.edges.find(e => e.cursor === edge.cursor);
    return updated ?? edge;
  });

  return {
    pages: queryData?.pages.map((page, index) =>
      index === 0
        ? {
            ...page,
            edges: [...newEdges, ...updatedEdges]
          }
        : page
    ),
    pageParams: queryData?.pageParams
  };
};

function getTypeLabel(execution: SyncExecutionFragment) {
  if (execution.resync) {
    return 'Full Resync';
  }
  if (execution.type === ExecutionTrigger.Dbtcloud) {
    return 'dbt Cloud';
  }
  if (execution.type === ExecutionTrigger.Api) {
    return 'API';
  }
  return capsFirst(execution.type);
}

function isRunning(execution: SyncExecutionFragment) {
  return [
    ExecutionStatus.Canceling,
    ExecutionStatus.Created,
    ExecutionStatus.Processing,
    ExecutionStatus.Queued,
    ExecutionStatus.Running,
    ExecutionStatus.Scheduled
  ].includes(execution.status);
}

interface SyncHistoryProps {
  sync: SyncFragment;
}

const SyncHistory = ({ sync }: SyncHistoryProps) => {
  const { id } = useParams<{ id: string }>();

  const queryClient = useQueryClient();
  const estimatedRowSize = useEstimatedRowSize({ small: 35.59, large: 39.5 });

  const [isRunningLoaded, setIsRunningLoaded] = React.useState<boolean>(false);
  const [isCompletedLoaded, setIsCompletedLoaded] = React.useState<boolean>(false);

  const [retryExecutionId, setRetryExecutionId] = React.useState<string>();
  const [executionRecords, setExecutionRecords] = React.useState<ExecutionRecordsDialog>({
    show: false,
    executionId: '',
    type: null
  });
  const [webhookRequests, setWebhookRequests] = React.useState({
    show: false,
    execution: ''
  });
  const dispatchBanner = useBannerDispatch();
  const [getExecs, { fetchMore, loading }] = useLazyQuery(SyncExecutionsDocument, {
    notifyOnNetworkStatusChange: true,
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first'
  });

  const [refetch] = useLazyQuery(SyncExecutionsDocument, {
    notifyOnNetworkStatusChange: true,
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } }),
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onCompleted: data => {
      queryClient.setQueryData([`${sync?.id}-history-running`], updateQueryData(data));
      queryClient.setQueryData([`${sync?.id}-history-completed`], updateQueryData(data));
    }
  });

  useSubscription(SyncStatusDocument, {
    skip: !id,
    variables: { syncID: id },
    onData: ({ data, client }) => {
      const syncStatus = data.data?.syncStatus;
      if (!syncStatus || !syncStatus.execution) {
        return;
      }
      refetch({ variables: { syncId: id, where } });
      client.cache.updateQuery(
        {
          query: SyncDocument,
          variables: { id }
        },
        (data: { sync: SyncFragment } | null) => {
          if (!data || !data.sync) {
            return;
          }
          return {
            sync: {
              ...data.sync,
              currentExecution: null,
              lastExecution: syncStatus.execution,
              nextExecutionTime: syncStatus.nextExecutionTime
            }
          };
        }
      );
    }
  });

  const { methods, refreshHistory, isSameFilters, where } = useSyncHistory();

  const [startSync, { loading: startSyncLoading }] = useMutation(StartSyncDocument, {
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: 'px-3 pt-3' } })
  });

  const syncFromCheckpoint = (executionId: string) => {
    startSync({
      variables: {
        id: sync?.id,
        opts: {
          checkpointExecution: executionId
        }
      }
    });
  };

  const isCheckpointable = sync?.fields?.some(field =>
    field?.model?.fieldset?.connection?.type?.operations?.includes(Operation.Checkpointable)
  );

  const getData = async ({ after }: PaginatedQueryParams) => {
    const { data } = after
      ? await fetchMore({ variables: { syncId: sync.id, where, after } })
      : await getExecs({ variables: { syncId: sync.id, where } });
    setIsCompletedLoaded(true);
    return data.syncExecutions;
  };

  const getRunningData = async () => {
    const { data } = await getExecs({
      variables: {
        syncId: sync.id,
        where: isEmpty(where) ? { status: { status: ExecutionStatus.Running } } : where
      }
    });
    setIsRunningLoaded(true);
    return data.syncExecutions;
  };

  React.useEffect(() => {
    if (!startSyncLoading) {
      setRetryExecutionId(undefined);
    }
  }, [startSyncLoading]);

  function SyncRecordCount(execution: SyncExecutionFragment) {
    function onClick() {
      setExecutionRecords({ show: true, executionId: execution.id, type: 'records' });
    }

    function onClickWebhook() {
      setWebhookRequests({ show: true, execution: execution.id });
    }

    if (execution.recordCount == null) {
      return emptyCell;
    }
    if (execution.status === ExecutionStatus.Running && execution.recordCount === 0) {
      return <span />;
    }

    if (sync?.targetConnection?.type.id !== 'webhook' && execution.records?.hasData) {
      return <LinkButton onClick={onClick}>{execution.recordCount.toLocaleString()}</LinkButton>;
    }
    if (sync?.targetConnection?.type.id === 'webhook' && execution.completedAt) {
      return (
        <LinkButton onClick={onClickWebhook}>{execution.recordCount.toLocaleString()}</LinkButton>
      );
    }
    return <p>{execution.recordCount.toLocaleString()}</p>;
  }

  function ExecutionErrorCount({ execution }: { execution: SyncExecutionFragment }) {
    function triggerSync() {
      setRetryExecutionId(execution.id);
      void startSync({
        variables: {
          id,
          opts: { retryExecution: execution.id }
        }
      });
    }

    const extraElements = execution.errors?.canRetry ? (
      <Button
        size="mini"
        iconEnd="RefreshSmall"
        onClick={triggerSync}
        loading={retryExecutionId === execution.id}
      >
        Retry
      </Button>
    ) : undefined;

    return (
      <RecordDisplayHelper
        setExecutionRecords={setExecutionRecords}
        execution={execution}
        type="errors"
        extraElements={extraElements}
      />
    );
  }

  const hasOperationCounts = !sync?.targetObject?.properties?.doesNotReportOperationCounts;

  const columns: ColumnDef<SyncExecutionFragment>[] = [
    {
      header: 'Sync time',
      accessorFn: row => row.startedAt,
      cell: ({ row }) => (
        <ExecutionStartTime
          id={row.original.id}
          syncId={sync.id}
          startedAt={row.original.startedAt || null}
          status={row.original.status}
        />
      ),
      size: 180
    },
    {
      header: 'Type',
      accessorFn: row => row.type,
      cell: ({ row }) => getTypeLabel(row.original),
      size: 90
    },
    {
      header: 'Duration',
      accessorFn: row => row.formattedDuration,
      cell: ({ row }) => row.original.formattedDuration ?? emptyCell,
      size: 95
    },
    {
      header: 'Total Records',
      accessorFn: row => row.recordCount,
      cell: ({ row }) => <SyncRecordCount {...row.original} />,
      size: 80
    },
    {
      header: 'Inserted',
      accessorFn: row => row.insertCount,
      cell: ({ row }) => (
        <RecordDisplayHelper
          setExecutionRecords={setExecutionRecords}
          execution={row.original}
          type="inserts"
        />
      ),
      isVisible:
        ![SyncMode.Update, SyncMode.Replace, SyncMode.Append, SyncMode.Remove].includes(
          sync?.mode
        ) && hasOperationCounts,
      size: 80
    },
    {
      header: 'Updated',
      accessorFn: row => row.updateCount,
      cell: ({ row }) => (
        <RecordDisplayHelper
          setExecutionRecords={setExecutionRecords}
          execution={row.original}
          type="updates"
        />
      ),
      isVisible:
        ![SyncMode.Create, SyncMode.Replace, SyncMode.Append].includes(sync?.mode) &&
        hasOperationCounts,
      size: 80
    },
    {
      header: 'Deleted',
      accessorFn: row => row?.deleteCount,
      cell: ({ row }) => (
        <RecordDisplayHelper
          setExecutionRecords={setExecutionRecords}
          execution={row.original}
          type="deletes"
        />
      ),
      isVisible: sync?.mode === SyncMode.Remove && hasOperationCounts,
      size: 70
    },
    {
      header: 'Errors',
      accessorFn: row => row.errorCount,
      cell: ({ row }) => <ExecutionErrorCount execution={row.original} />,
      size: 70
    },
    {
      header: 'Warnings',
      accessorFn: row => row.warningCount,
      cell: ({ row }) => (
        <RecordDisplayHelper
          setExecutionRecords={setExecutionRecords}
          execution={row.original}
          type="warnings"
        />
      ),
      size: 70
    },
    {
      header: 'Status',
      accessorFn: row => row.status,
      cell: ({ row }) => <ExecutionStatusDisplay execution={row.original} />,
      size: 115
    }
  ];

  if (isCheckpointable) {
    columns.push({
      id: 'actions',
      header: null,
      cell: ({ row }) => (
        <>
          {row.original.checkpointable && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="invisible rounded p-[2px] hover:bg-gray-300 group-hover/row:visible">
                  <Icon name="DotsH" size="sm" />
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" portal={false}>
                <DropdownMenuItem
                  onClick={() => syncFromCheckpoint(row.original.id)}
                  className="flex-col items-start"
                >
                  <p>Start sync from here</p>
                  <p className="text-gray-500">Begin from this history checkpoint</p>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </>
      ),
      size: 50
    });
  }

  return (
    <>
      <HistoryFilters
        historyFilters={getHistoryFilters(sync)}
        methods={methods}
        applyFiltersControl={
          <Button
            className="whitespace-nowrap"
            disabled={isSameFilters}
            loading={loading}
            onClick={refreshHistory}
          >
            Apply filters
          </Button>
        }
      />
      {!(isCompletedLoaded && isRunningLoaded) && (
        <div className="mt-24 flex h-full w-full items-center justify-center">
          <LoadingDots />
        </div>
      )}
      <section
        className={cn(
          'flex max-h-full w-max min-w-full flex-col space-y-4 overflow-auto p-6',
          !(isCompletedLoaded && isRunningLoaded) && 'opacity-0'
        )}
      >
        <h3 className="text-lg text-gray-800">Running</h3>
        <DataTableVirtual<SyncExecutionFragment>
          columns={columns}
          getData={getRunningData}
          filters={where}
          classNames={{ cell: 'align-top', wrapper: 'h-fit' }}
          queryKey={`${sync?.id}-history-running`}
          disableFetchMore={true}
          globalFilter={row => isRunning(row.original)}
          emptyComponent={
            <div className="rounded border bg-white py-2 px-4 text-gray-500 shadow-sm">
              <p>No syncs running.</p>
            </div>
          }
          estimateSize={() => estimatedRowSize}
        />
        <h3 className="text-lg text-gray-800">Completed</h3>
        <DataTableVirtual<SyncExecutionFragment>
          columns={columns}
          getData={getData}
          filters={where}
          classNames={{ cell: 'align-top', wrapper: 'flex-1' }}
          queryKey={`${sync?.id}-history-completed`}
          globalFilter={row => !isRunning(row.original)}
          emptyComponent={
            <div className="rounded border bg-white py-2 px-4 text-gray-500 shadow-sm">
              <p>No completed syncs.</p>
            </div>
          }
          estimateSize={() => estimatedRowSize}
        />
      </section>
      {executionRecords.show && (
        <SyncExecutionRecordsPreview
          heading={recordDialogHeading(executionRecords.type)}
          executionId={executionRecords.executionId}
          recordType={executionRecords.type}
          targetConnectionTypeId={sync?.targetConnection?.type.id}
          targetConnectionName={sync?.targetConnection?.name}
          targetObjectName={sync?.targetObject?.name}
          handleDismiss={() => {
            setExecutionRecords({
              show: false,
              executionId: '',
              type: null
            });
          }}
        />
      )}

      {webhookRequests.show && (
        <WebhookSyncRequests
          referenceId={webhookRequests.execution}
          handleClose={() => {
            setWebhookRequests({ show: false, execution: '' });
          }}
        />
      )}
    </>
  );
};

function ExecutionStartTime(props: {
  id: string;
  syncId: string;
  startedAt: string | null;
  status: ExecutionStatus;
}) {
  const { state: configuration } = useConfiguration();
  if (!props.startedAt) {
    return emptyCell;
  }
  const label = getLongLocalTime(props.startedAt);

  if (
    configuration.on_premises &&
    ![ExecutionStatus.Scheduled, ExecutionStatus.Queued, ExecutionStatus.Created].includes(
      props.status
    ) &&
    props.syncId &&
    props.id
  ) {
    return (
      <a
        className="link block whitespace-nowrap"
        href={`/api/executions/${props.syncId}/${props.id}/log.json`}
      >
        {label}
      </a>
    );
  }
  return <span className="whitespace-nowrap">{label}</span>;
}

function RecordDisplayHelper(props: {
  setExecutionRecords: React.Dispatch<React.SetStateAction<ExecutionRecordsDialog>>;
  execution: SyncExecutionFragment;
  type: ExecutionRecordType;
  extraElements?: JSX.Element;
}) {
  function onClick() {
    props.setExecutionRecords({ show: true, executionId: props.execution.id, type: props.type });
  }
  const { count, hasData } = prepareRecordDisplay(props.type, props.execution);
  if (count == null || count == 0) {
    return emptyCell;
  }

  const countStr = count.toLocaleString();
  if (count > 0 && hasData) {
    if (props.extraElements) {
      return (
        <div className="flex flex-col items-start justify-between space-y-2">
          <LinkButton onClick={onClick}>{countStr}</LinkButton>
          {props.extraElements}
        </div>
      );
    }
    return <LinkButton onClick={onClick}>{countStr}</LinkButton>;
  }
  return <p>{countStr}</p>;
}

function ExecutionStatusDisplay({ execution }: { execution: SyncExecutionFragment }) {
  switch (execution.status) {
    case ExecutionStatus.Created:
      return <Status icon="DotsH">Starting</Status>;
    case ExecutionStatus.Running:
      return (
        <Status icon="ArrowRightFilled" classNames={{ icon: 'text-blue-500' }}>
          Running
        </Status>
      );
    case ExecutionStatus.Completed:
      if (execution.errorCount && execution.errorCount > 0) {
        return (
          <Status
            icon="WarningFilled"
            classNames={{ icon: 'text-amber-500' }}
            tooltipContent="Completed with errors"
          >
            Completed
          </Status>
        );
      }
      return (
        <Status icon="CheckFilled" classNames={{ icon: 'text-green-500' }}>
          Completed
        </Status>
      );
    case ExecutionStatus.Failed:
      return (
        <Status
          icon="DangerFilled"
          classNames={{ icon: 'text-red-500' }}
          tooltipContent={handleExecutionFailedTooltip(execution)}
          interactive={true}
        >
          Failed
        </Status>
      );
    case ExecutionStatus.Canceled:
      return (
        <Status icon="CloseFilled" classNames={{ icon: 'text-gray-500' }}>
          Canceled
        </Status>
      );
    case ExecutionStatus.Processing:
      return (
        <Status icon="DotsH" classNames={{ icon: 'text-gray-500' }}>
          Processing
        </Status>
      );
    case ExecutionStatus.Scheduled:
      return (
        <Status icon="DotsH" classNames={{ icon: 'text-gray-500' }}>
          Scheduled
        </Status>
      );
    default:
      return <p className="text-gray-800">{capsFirst(execution.status)}</p>;
  }
}

function handleExecutionFailedTooltip(execution: SyncExecutionFragment) {
  if (!execution.executionErrors || execution.executionErrors.length === 0) {
    return 'Sync failed';
  }

  return execution.executionErrors.map(e => e.error.replace(SYNC_FAILURE_ERROR_SUFFIX, ''));
}

export default SyncHistory;
