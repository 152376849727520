import { Location } from 'history';
import * as React from 'react';
import { Prompt, useHistory } from 'react-router-dom';

import { Action } from '../generated/graphql';
import { useHasAclPermission } from '../hooks';
import { Button } from '.';
import { Dialog } from './v3';

interface Props {
  when: boolean;
  heading?: string;
  message?: string;
}

export const PromptUnsaved = ({
  when,
  heading = 'Discard unsaved changes?',
  message = 'Leaving this page will discard your unsaved changes.'
}: Props) => {
  const [modalVisible, setModalVisible] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);
  const history = useHistory();
  const canEdit = useHasAclPermission(Action.Edit);

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Preserve the full location object including state
      history.push(lastLocation);
    }
  }, [confirmedNavigation, lastLocation, history]);

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const dismiss = () => {
    setModalVisible(false);
  };

  return (
    <>
      <Prompt when={canEdit && when} message={handleBlockedNavigation} />
      <Dialog
        show={modalVisible}
        size="xs"
        heading={heading}
        onDismiss={dismiss}
        actions={
          <>
            <Button onClick={dismiss}>Resume editing</Button>
            <Button theme="danger" onClick={handleConfirmNavigationClick}>
              Discard changes
            </Button>
          </>
        }
      >
        <p className="text-sm text-gray-600 [max-width:65ch]">{message}</p>
      </Dialog>
    </>
  );
};
