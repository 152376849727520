import { ActivityType, Category, genericEvent, renderers } from './ActivityTypes';

export const syncCategories: Record<string, Category> = {
  syncName: { label: 'Sync name and notes', value: 'syncName' },
  otherSettings: { label: 'Other settings', value: 'otherSettings' },
  syncEnabled: { label: 'Sync enabled', value: 'syncEnabled' },
  syncFiltersAndOverrides: { label: 'Filters and overrides', value: 'syncFiltersAndOverrides' },
  syncSchedule: { label: 'Schedule', value: 'syncSchedule' },
  fieldMappings: { label: 'Field mappings', value: 'fieldMappings' },
  permissions: { label: 'Permissions', value: 'permissions' },
  notifications: { label: 'Notifications', value: 'notifications' },
  targetConfiguration: { label: 'Destination', value: 'targetConfiguration' }
};

export const syncActivityTypes: Record<string, ActivityType> = {
  // Connection
  'model_sync.connection.connection_changed': {
    category: syncCategories.syncConnection,
    label: 'Connection',
    render: renderers.syncConnectionDiff,
    order: 0
  },

  // Destination
  'model_sync.target.object_updated': {
    category: syncCategories.targetConfiguration,
    label: 'Target object',
    render: renderers.strDiff,
    order: 1
  },
  'model_sync.target.schema_updated': {
    category: syncCategories.targetConfiguration,
    label: 'Target schema',
    render: renderers.strDiff,
    order: 2
  },
  'model_sync.target.table_updated': {
    category: syncCategories.targetConfiguration,
    label: 'Target table',
    render: renderers.strDiff,
    order: 3
  },

  // Sync Mode
  'model_sync.active_changed': {
    category: syncCategories.syncEnabled,
    label: 'Sync enabled',
    render: renderers.boolDiff,
    order: 5
  },
  'model_sync.sync_mode_changed': {
    category: syncCategories.otherSettings,
    label: 'Mode',
    render: renderers.strDiff,
    order: 6
  },
  'model_sync.sync_all_records': {
    category: syncCategories.otherSettings,
    label: 'Sync all records',
    render: renderers.boolDiff,
    order: 7
  },
  'model_sync.retry_errors': {
    category: syncCategories.otherSettings,
    label: 'Retry errors',
    render: renderers.boolDiff,
    order: 8
  },

  // Identity Mappings
  'model_sync.identity.added': {
    category: syncCategories.fieldMappings,
    label: 'Identity mapping',
    render: renderers.identityMappingDiff,
    order: 9
  },
  'model_sync.identity.removed': {
    category: syncCategories.fieldMappings,
    label: 'Identity mapping',
    render: renderers.identityMappingDiff,
    order: 10
  },
  'model_sync.identity.updated': {
    category: syncCategories.fieldMappings,
    label: 'Identity mapping',
    render: renderers.identityMappingDiff,
    order: 11
  },

  // Field Mappings
  'model_sync.field_mapping.added': {
    category: syncCategories.fieldMappings,
    label: 'Field mappings',
    render: renderers.fieldMappingDiff,
    order: 12
  },
  'model_sync.field_mapping.removed': {
    category: syncCategories.fieldMappings,
    label: 'Field mappings',
    render: renderers.fieldMappingDiff,
    order: 13
  },
  'model_sync.field_mapping.updated': {
    category: syncCategories.fieldMappings,
    label: 'Field mappings',
    render: renderers.fieldMappingDiff,
    order: 14
  },

  // Sync Filters / Logic
  'model_sync.filter.added': {
    category: syncCategories.syncFiltersAndOverrides,
    label: 'Filter',
    render: renderers.syncFilterDiff,
    order: 15
  },
  'model_sync.filter.removed': {
    category: syncCategories.syncFiltersAndOverrides,
    label: 'Filter',
    render: renderers.syncFilterDiff,
    order: 16
  },
  'model_sync.filter_logic_changed': {
    category: syncCategories.syncFiltersAndOverrides,
    label: 'Filter logic',
    render: renderers.strDiff,
    order: 17
  },

  // Sync Overrides
  'model_sync.override.added': {
    category: syncCategories.syncFiltersAndOverrides,
    label: 'Override',
    render: renderers.syncOverrideDiff,
    order: 18
  },
  'model_sync.override.removed': {
    category: syncCategories.syncFiltersAndOverrides,
    label: 'Override',
    render: renderers.syncOverrideDiff,
    order: 19
  },

  // Schedule
  'model_sync.schedule.changed': {
    category: syncCategories.syncSchedule,
    label: 'Schedule',
    render: renderers.syncScheduleDiff,
    order: 20
  },

  // Name / Notes
  'model_sync.name_changed': {
    category: syncCategories.syncName,
    label: 'Name',
    render: renderers.strDiff,
    order: 21
  },
  'model_sync.notes_changed': {
    category: syncCategories.syncName,
    label: 'Notes',
    render: renderers.strDiff,
    order: 22
  },

  // Permissions
  'permissions_tag.added': {
    category: syncCategories.permissions,
    label: 'Permission',
    render: renderers.strDiff,
    order: 23
  },

  // Advanced settings
  'model_sync.target_configuration.*': {
    category: syncCategories.targetConfiguration,
    label: ({ log }) => `${log.context.title}`,
    render: ({ log }) => {
      const l = genericEvent.parse(log);
      switch (l?.eventSchema) {
        case 'generic_bool':
          return renderers.boolDiff({ log });
        case 'generic_string':
          return renderers.strDiff({ log });
        case 'generic_array':
          return renderers.arrayDiff({ log });
        case 'generic_int':
          return renderers.intDiff({ log });
        case 'generic_float':
          return renderers.floatDiff({ log });
        default:
          return null;
      }
    },
    order: 4
  },

  // Notifications
  'model_sync.partial_failure_notifications': {
    category: syncCategories.notifications,
    label: 'Error notifications',
    render: renderers.boolDiff,
    order: 24
  },
  'model_sync.warning_notifiations': {
    category: syncCategories.notifications,
    label: 'Warning notifications',
    render: renderers.boolDiff,
    order: 25
  },
  'notification.added': {
    category: syncCategories.notifications,
    label: 'Error notification subscribers',
    render: renderers.notificationDiff,
    order: 26
  },
  'notification.removed': {
    category: syncCategories.notifications,
    label: 'Error notification subscribers',
    render: renderers.notificationDiff,
    order: 27
  },

  // Created/Deleted (no category)
  'model_sync.created': {
    category: null,
    label: 'Sync created',
    render: () => null,
    order: 28
  },
  'model_sync.deleted': {
    category: null,
    label: 'Sync deleted',
    render: () => null,
    order: 29
  }
};
