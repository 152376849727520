import _, { find, isArray, isEmpty } from 'lodash';
import { ReactElement } from 'react';
import { z } from 'zod';
import { Icon, Tooltip } from '~/components';
import {
  ActivityLogFragment,
  EditableFieldsetFragment,
  ScheduleUpdate,
  SyncFragment
} from '~/generated/graphql';
import Marker from '../ActivityLogMarker';
import { ScheduleToDisplay } from '~/pages/syncs/sync-config';

// Todo - we could reshape the data to be easier to manage on the FE,
// but it would be harder to debug missing activityTypes
export interface ActivityLogItem extends ActivityLogFragment {
  label: string;
}

type Context = EditableFieldsetFragment | SyncFragment;

export interface Change {
  changeID: string;
  performedAt: string;
  performedBy: {
    id?: string | null;
    actorType: 'user' | 'system' | 'partner' | 'organization' | 'support';
    displayName: string;
  };
  logs: {
    [label: string]: ActivityLogItem[];
  };
}
export interface Category {
  label: string | ((props: { context?: Context }) => string);
  value: string;
  shouldRender?: (props: { context?: Context }) => boolean;
}

export const relationship = z.object({
  fieldName: z.string(),
  fieldID: z.string(),
  foreignFieldName: z.string(),
  foreignFieldID: z.string(),
  foreignFieldsetType: z.string(),
  foreignFieldsetID: z.string(),
  foreignFieldsetName: z.string()
});

export const valueLabel = z.object({
  label: z.string(),
  value: z.string()
});

export const fieldMapping = z
  .object({
    toName: z.string(),
    toSystemName: z.string(),
    toSystemType: z.string(),
    toSystemID: z.string(),
    fromName: z.string(),
    fromSystem: z.string(),
    fromFieldsetName: z.string(),
    fromFieldsetID: z.string().nullable(),
    OverrideValue: z.string(),
    PreserveValues: z.boolean()
  })
  .nullable();

export const identityMapping = z
  .object({
    toName: z.string(),
    toSystemName: z.string(),
    toSystemType: z.string(),
    toSystemID: z.string(),
    fromName: z.string(),
    fromSystem: z.string(),
    fromFieldsetName: z.string(),
    fromFieldsetID: z.string().nullable(),
    function: z.string()
  })
  .nullable();

export const syncSchedule = z.object({
  DBTConnection: z.string(),
  dayOfMonth: z.string(),
  dayOfWeek: z.string(),
  frequency: z.string(),
  hour: z.string(),
  minute: z.string(),
  month: z.string(),
  runAfterSuccessOnly: z.boolean(),
  runAfterSyncs: z
    .array(
      z.object({
        elem1: z.string(),
        elem2: z.string()
      })
    )
    .nullable()
});

export const syncFilter = z
  .object({
    type: z.string(),
    fieldsetID: z.string(),
    fieldsetSystem: z.string(),
    fieldsetName: z.string(),
    fieldName: z.string(),
    functionType: z.string(),
    functionLabel: z.string(),
    Values: z.string().or(z.array(z.string()))
  })
  .nullable();

export const syncOverride = z
  .object({
    fieldName: z.string(),
    fieldsetID: z.string(),
    fieldsetSystem: z.string(),
    fieldsetName: z.string(),
    functionLabel: z.string(),
    functionType: z.string(),
    checkValue: z.string().or(z.array(z.string())).nullable(),
    replacement: z.string()
  })
  .nullable();

export const notificationChange = z.object({
  recipientID: z.string(),
  recipientType: z.string(),
  eventType: z.string()
});

export const genericEvent = z.object({
  old: z.any(),
  new: z.any(),
  eventSchema: z.string()
});

export const syncConnection = z.object({
  ID: z.string(),
  name: z.string(),
  systemType: z.string()
});

export const schemas = {
  newStr: z.object({ new: z.object({ str: z.string() }) }),
  oldStr: z.object({ old: z.object({ str: z.string() }) }),
  newBool: z.object({ new: z.object({ val: z.boolean() }) }),
  oldBool: z.object({ old: z.object({ val: z.boolean() }) }),
  newInt: z.object({ new: z.object({ int: z.number() }) }),
  oldInt: z.object({ old: z.object({ int: z.number() }) }),
  newFloat: z.object({ new: z.object({ float: z.number() }) }),
  oldFloat: z.object({ old: z.object({ float: z.number() }) }),
  newArray: z.object({ new: z.object({ arr: z.array(z.string()) }) }),
  oldArray: z.object({ old: z.object({ arr: z.array(z.string()) }) }),
  newPair: z.object({ new: z.object({ elem1: z.string(), elem2: z.string() }) }),
  oldPair: z.object({ old: z.object({ elem1: z.string(), elem2: z.string() }) }),
  fieldContext: z.object({ context: z.object({ source_name: z.string() }) }),
  newRelationship: z.object({ new: relationship }),
  oldRelationship: z.object({ old: relationship }),
  oldValueLabel: z.object({ old: valueLabel }),
  newValueLabel: z.object({ new: valueLabel }),
  fieldMappingChange: z.object({ old: fieldMapping, new: fieldMapping }),
  syncScheduleChange: z.object({ old: syncSchedule, new: syncSchedule }),
  syncFilterChange: z.object({ old: syncFilter, new: syncFilter }),
  syncOverride: z.object({ old: syncOverride, new: syncOverride }),
  notification: z.object({ old: notificationChange, new: notificationChange }),
  syncConnection: z.object({ old: syncConnection, new: syncConnection }),
  identityMapping: z.object({ old: identityMapping, new: identityMapping })
};

const MappingDisplay = ({
  fromSystem,
  fromName,
  fromFieldsetName,
  toSystemType,
  toName,
  toSystemName
}: z.infer<typeof fieldMapping>) => (
  <div className="flex gap-1">
    <Tooltip content={`${fromFieldsetName} > ${fromName}`} offset={[0, 4]}>
      <div className="flex gap-1">
        <Icon match={fromSystem} />
        <span>{fromName}</span>
      </div>
    </Tooltip>

    <Icon name="ArrowNarrowRight" className="text-gray-500" />

    <Tooltip content={`${toSystemName} > ${toName}`} offset={[0, 4]}>
      <div className="flex gap-1">
        <Icon match={toSystemType} />
        <span>{toName}</span>
      </div>
    </Tooltip>
  </div>
);

const ValueDisplay = ({ label, value }) => (
  <div className="flex items-center gap-1">
    <span className="text-gray-500">{label}</span>
    <span className="rounded-md bg-gray-100 px-1 py-0.5 text-gray-600">{value}</span>
  </div>
);

export const renderers = {
  strDiff: ({ log }) => {
    const l = z.intersection(schemas.newStr, schemas.oldStr).parse(log);
    return (
      <>
        <Marker type="minus">{l.old.str}</Marker>
        <Marker type="plus">{l.new.str}</Marker>
      </>
    );
  },
  boolDiff: ({ log }) => {
    const l = z.intersection(schemas.oldBool, schemas.newBool).parse(log);
    return (
      <>
        <Marker type="minus">{l.old.val ? 'true' : 'false'}</Marker>
        <Marker type="plus">{l.new.val ? 'true' : 'false'}</Marker>
      </>
    );
  },
  intDiff: ({ log }) => {
    const l = z.intersection(schemas.newInt, schemas.oldInt).parse(log);
    return (
      <>
        <Marker type="minus">{l.old.int}</Marker>
        <Marker type="plus">{l.new.int}</Marker>
      </>
    );
  },
  floatDiff: ({ log }) => {
    const l = z.intersection(schemas.newFloat, schemas.oldFloat).parse(log);
    return (
      <>
        <Marker type="minus">{l.old.float}</Marker>
        <Marker type="plus">{l.new.float}</Marker>
      </>
    );
  },
  arrayDiff: ({ log }) => {
    const l = z.intersection(schemas.newArray, schemas.oldArray).parse(log);
    return (
      <>
        {l.old?.arr?.length > 0 && <Marker type="minus">{l.old.arr.join(', ')}</Marker>}
        {l.new?.arr?.length > 0 && <Marker type="plus">{l.new.arr.join(', ')}</Marker>}
      </>
    );
  },
  pairDiff: ({ log }) => {
    const l = z.intersection(schemas.newPair, schemas.oldPair).parse(log);
    return (
      <>
        <Marker type="minus">{`${l.old.elem1} → ${l.old.elem2}`}</Marker>
        <Marker type="plus">{`${l.new.elem1} → ${l.new.elem2}`}</Marker>
      </>
    );
  },
  valueLabelDiff: ({ log }) => {
    const l = z.intersection(schemas.newValueLabel, schemas.oldValueLabel).parse(log);
    return (
      <>
        {l.old?.label?.length > 0 && (
          <Marker type="minus">
            <Tooltip content={`Internal value: ${l.old.value}`} offset={[0, 4]}>
              <span>{l.old.label}</span>
            </Tooltip>
          </Marker>
        )}
        {l.new?.label?.length > 0 && (
          <Marker type="plus">
            <Tooltip content={`Internal value: ${l.new.value}`} offset={[0, 4]}>
              <span>{l.new.label}</span>
            </Tooltip>
          </Marker>
        )}
      </>
    );
  },
  fieldMappingDiff: ({ log }) => {
    const l = schemas.fieldMappingChange.parse(log);
    const fieldsUnchanged = [
      'fromName',
      'toName',
      'fromFieldsetName',
      'toFieldsetName',
      'fromSystem',
      'toSystem',
      'toSystemType',
      'toSystemID'
    ].every(key => l.old?.[key] === l.new?.[key]);

    return (
      <div className="mt-1 flex flex-col gap-1">
        {fieldsUnchanged ? (
          <MappingDisplay {...l.old} />
        ) : (
          <>
            {!isEmpty(l.old?.fromName) && (
              <Marker type="minus">
                <MappingDisplay {...l.old} />
              </Marker>
            )}
            {!isEmpty(l.new?.fromName) && (
              <Marker type="plus">
                <MappingDisplay {...l.new} />
              </Marker>
            )}
          </>
        )}

        {!!l.new?.PreserveValues !== !!l.old?.PreserveValues && (
          <>
            <Marker type="minus">
              <ValueDisplay
                label="Destination sync mode"
                value={l.old?.PreserveValues ? 'Do not override existing' : 'Always sync'}
              />
            </Marker>
            <Marker type="plus">
              <ValueDisplay
                label="Destination sync mode"
                value={l.new?.PreserveValues ? 'Do not override existing' : 'Always sync'}
              />
            </Marker>
          </>
        )}

        {(!isEmpty(l.new?.OverrideValue) || !isEmpty(l.old?.OverrideValue)) &&
          l.new?.OverrideValue !== l.old?.OverrideValue && (
            <>
              <Marker type="minus">
                <ValueDisplay label="Override value" value={l.old?.OverrideValue} />
              </Marker>
              <Marker type="plus">
                <ValueDisplay label="Override value" value={l.new?.OverrideValue} />
              </Marker>
            </>
          )}
      </div>
    );
  },
  identityMappingDiff: ({ log }) => {
    const l = schemas.identityMapping.parse(log);
    const fieldsUnchanged = [
      'fromName',
      'toName',
      'fromFieldsetName',
      'toFieldsetName',
      'fromSystem',
      'toSystem',
      'toSystemType',
      'toSystemID'
    ].every(key => l.old?.[key] === l.new?.[key]);

    return (
      <div className="mt-1 flex flex-col gap-1">
        {fieldsUnchanged ? (
          <MappingDisplay {...l.old} />
        ) : (
          <>
            {!isEmpty(l.old?.fromName) && (
              <Marker type="minus">
                <MappingDisplay {...l.old} />
              </Marker>
            )}
            {!isEmpty(l.new?.fromName) && (
              <Marker type="plus">
                <MappingDisplay {...l.new} />
              </Marker>
            )}
          </>
        )}

        {(!isEmpty(l.new?.function) || !isEmpty(l.old?.function)) &&
          l.new?.function !== l.old?.function && (
            <>
              <Marker type="minus">
                <ValueDisplay label="Identity function" value={l.old?.function} />
              </Marker>
              <Marker type="plus">
                <ValueDisplay label="Identity function" value={l.new?.function} />
              </Marker>
            </>
          )}
      </div>
    );
  },
  syncScheduleDiff: ({ log }) => {
    const l = schemas.syncScheduleChange.parse(log);
    return (
      <>
        {l.old.frequency && (
          <Marker type="minus">
            <ScheduleToDisplay
              schedule={l.old as ScheduleUpdate}
              runAfterSyncs={
                l.old.runAfterSyncs?.map(v => ({
                  id: v.elem1,
                  name: v.elem2
                })) ?? []
              }
            />
          </Marker>
        )}
        {l.new.frequency && (
          <Marker type="plus">
            <ScheduleToDisplay
              schedule={l.new as ScheduleUpdate}
              runAfterSyncs={
                l.new.runAfterSyncs?.map(v => ({
                  id: v.elem1,
                  name: v.elem2
                })) ?? []
              }
            />
          </Marker>
        )}
      </>
    );
  },
  syncFilterDiff: ({ log }) => {
    const l = schemas.syncFilterChange.parse(log);
    return (
      <div className="mt-1">
        {l.old && (
          <Marker type="minus">
            <div className="flex items-center gap-1">
              <Tooltip content={`${l.old.fieldsetName} > ${l.old.fieldName}`} offset={[0, 4]}>
                <div className="flex gap-2">
                  <Icon match={l.old.fieldsetSystem} />
                  <span>{l.old.fieldName}</span>
                </div>
              </Tooltip>
              <span className="text-gray-500">{l.old.functionLabel}</span>
              {(isArray(l.old.Values) ? l.old.Values : [l.old.Values]).map(v => (
                <span key={v} className="rounded-md bg-gray-100 px-1 py-0.5 text-gray-600">
                  {v}
                </span>
              ))}
            </div>
          </Marker>
        )}
        {l.new && (
          <Marker type="plus">
            <div className="flex items-center gap-1">
              <Tooltip content={`${l.new.fieldsetName} > ${l.new.fieldName}`} offset={[0, 4]}>
                <div className="flex gap-2">
                  <Icon match={l.new.fieldsetSystem} />
                  <span>{l.new.fieldName}</span>
                </div>
              </Tooltip>
              <span className="text-gray-500">{l.new.functionLabel}</span>
              {(isArray(l.new.Values) ? l.new.Values : [l.new.Values]).map(v => (
                <span key={v} className="rounded-md bg-gray-100 px-1 py-0.5 text-gray-600">
                  {v}
                </span>
              ))}
            </div>
          </Marker>
        )}
      </div>
    );
  },
  syncOverrideDiff: ({ log }) => {
    const l = schemas.syncOverride.parse(log);
    return (
      <div className="mt-1">
        {l.old && (
          <Marker type="minus">
            <div className="flex items-center gap-1">
              <Tooltip content={`${l.old.fieldsetName} > ${l.old.fieldName}`} offset={[0, 4]}>
                <div className="flex gap-2">
                  <Icon match={l.old.fieldsetSystem} />
                  <span>{l.old.fieldName}</span>
                </div>
              </Tooltip>
              <span className="text-gray-500">{l.old.functionLabel}</span>
              {(isArray(l.old.checkValue) ? l.old.checkValue : [l.old.checkValue]).map(v => (
                <span key={v} className="rounded-md bg-gray-100 px-1 py-0.5 text-gray-600">
                  {v}
                </span>
              ))}
              <Icon name="ArrowNarrowRight" className="text-gray-500" />
              <span className="rounded-md bg-gray-100 px-1 py-0.5 text-gray-600">
                {l.old.replacement}
              </span>
            </div>
          </Marker>
        )}
        {l.new && (
          <Marker type="plus">
            <div className="flex items-center gap-1">
              <Tooltip content={`${l.new.fieldsetName} > ${l.new.fieldName}`} offset={[0, 4]}>
                <div className="flex gap-2">
                  <Icon match={l.new.fieldsetSystem} />
                  <span>{l.new.fieldName}</span>
                </div>
              </Tooltip>
              <span className="text-gray-500">{l.new.functionLabel}</span>
              {(isArray(l.new.checkValue) ? l.new.checkValue : [l.new.checkValue]).map(v => (
                <span key={v} className="rounded-md bg-gray-100 px-1 py-0.5 text-gray-600">
                  {v}
                </span>
              ))}
              <Icon name="ArrowNarrowRight" className="text-gray-500" />
              <span className="rounded-md bg-gray-100 px-1 py-0.5 text-gray-600">
                {l.new.replacement}
              </span>
            </div>
          </Marker>
        )}
      </div>
    );
  },
  notificationDiff: ({ log }) => {
    const l = schemas.notification.parse(log);
    return (
      <>
        {!isEmpty(l.old.recipientID) && (
          <Marker type="minus">
            <span>{l.old.recipientID}</span>
          </Marker>
        )}
        {!isEmpty(l.new.recipientID) && (
          <Marker type="plus">
            <span>{l.new.recipientID}</span>
          </Marker>
        )}
      </>
    );
  },
  syncConnectionDiff: ({ log }) => {
    const l = schemas.syncConnection.parse(log);
    return (
      <div className="mt-1">
        {!isEmpty(l.old.ID) && (
          <Marker type="minus">
            <div className="flex gap-1">
              <Icon match={l.old.systemType} />
              <span>{l.old.name}</span>
            </div>
          </Marker>
        )}
        {!isEmpty(l.new.ID) && (
          <Marker type="plus">
            <div className="flex gap-1">
              <Icon match={l.new.systemType} />
              <span>{l.new.name}</span>
            </div>
          </Marker>
        )}
      </div>
    );
  }
};

// Filter rules allow logs to be filtered out from being displayed
export type FilterRule = ({
  log,
  logs
}: {
  log: ActivityLogFragment;
  logs: ActivityLogFragment[];
}) => boolean;
export const filterRules: FilterRule[] = [
  // Filter out field created and field deleted events unless context has published: true
  ({ log }: { log: ActivityLogFragment }) => {
    if (['field.created', 'field.deleted'].includes(log.activityType)) {
      return !!log.context?.published;
    }
    return true;
  },
  // Filter out any logs with the same changeID as the model created log
  ({ log, logs }) => {
    const createdLog = find(logs, { activityType: 'model.created' });
    return log.changeID !== createdLog?.changeID || log.id === createdLog?.id;
  },
  // Filter out any logs with the same changeID as the model sync created log or performed before it
  ({ log, logs }) => {
    const createdLog = find(logs, { activityType: 'model_sync.created' });
    return (
      (log.changeID !== createdLog?.changeID || log.id === createdLog?.id) &&
      (createdLog?.performedAt ? log?.performedAt >= createdLog?.performedAt : true)
    );
  }
];

export interface ActivityType {
  // Category is used for filtering
  category: Category;
  // Label is rendered at the group level
  label: string | ((props: { log?: ActivityLogFragment; context?: Context }) => string);
  // Render will throw an error message from zod parse if the data shape doesn't match
  render: (props: { log: ActivityLogItem }) => ReactElement;
  order?: number;
}

// Add a helper function to find matching activity type
export const findMatchingActivityType = (
  activityTypes: Record<string, ActivityType>,
  logType: string
): ActivityType | undefined => {
  // First try exact match
  if (activityTypes[logType]) {
    return activityTypes[logType];
  }

  // Then try regex patterns
  for (const [pattern, activityType] of Object.entries(activityTypes)) {
    if (pattern.includes('*')) {
      const regexPattern = new RegExp(
        '^' + pattern.replace(/\./g, '\\.').replace(/\*/g, '.*') + '$'
      );
      if (regexPattern.test(logType)) {
        return activityType;
      }
    }
  }

  return undefined;
};
