import {
  ApolloError,
  Reference,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { NIL } from 'uuid';
import { Icon } from '~/components';
import { Details } from '~/components/v3';

import {
  AccessControlInForm,
  AccessControlWrap,
  Button,
  EditPermission,
  FeatureFlag,
  LacksPermissionBanner,
  PromptUnsaved,
  SideBySide
} from '~/components';
import ConfigTriggerDialog from '~/components/config-trigger-dialog';
import PageLayout from '~/components/v2/layout/PageLayout';
import {
  CompletionValue,
  ConnectionAuthDocument,
  ConnectionDeletedSuccessResponse,
  ConnectionDeleteErrorFragment,
  ConnectionDocument,
  ConnectionErrorResponse,
  ConnectionParameterCompletionsDocument,
  ConnectionsDocument,
  ConnectionTypeDocument,
  ConnectionTypeFragment,
  ConnectionUsedBy,
  CreateConnectionDocument,
  DeleteConnectionDocument,
  EditableConnectionFragment,
  HealthStatus,
  Operation,
  ResourceType,
  UpdateConnectionDocument
} from '~/generated/graphql';
import { AclProvider, useAuth, useBannerDispatch } from '~/hooks';
import { hasItems, NO_EDIT_PERMISSION, routes, Selectable, transformFormData } from '~/utils';
import {
  APIConnectionConfig,
  CSVConnectionConfig,
  DefaultConnectionConfig,
  EnrichmentConnectionConfig,
  GsheetsConnectionConfig,
  OauthConnectionConfig,
  SnowflakeConnectionConfig,
  WebhookConnectionConfig
} from './backends';
import { ConnectionInUse, ConnectionName } from '~/pages/connections/connection-components';
import ConnectionObjectCachingOption from './connection-components/ConnectionObjectCachingOption';
import ConnectionIPWhitelist from './connection-components/ConnectionIPWhitelist';
import { useConfiguration } from '~/hooks/configuration';
import { JiraConnectionConfig } from './backends/jira-connection';
import { ZendeskConnectionConfig } from './backends/zendesk-connection';
import { FacebookAudienceConnectionConfig } from './backends/fbaudience-connection';
import { SalesforceConnectionConfig } from './backends/salesforce-connection';
import { GongConnectionConfig } from './backends/gong-connection';
import { SalesloftConnectionConfig } from './backends/salesloft-connection';
import { DropboxConnectionConfig } from './backends/dropbox-connection';
import { AzureBlobConnectionConfig } from './backends/azureblob-connection';
import { DialpadConnectionConfig } from './backends/dialpad-connection';

const wrapperStyles = 'px-3 pt-3';

interface ParamValues {
  connectionTypeId: string;
  id: string;
}

export type ConnectionWithoutType = Omit<EditableConnectionFragment, 'type'>;
export type ConnectionFormValues = Pick<ConnectionWithoutType, 'name' | 'configuration' | 'tags'>;

export function ConnectionConfig() {
  const { id, connectionTypeId } = useParams<ParamValues>();
  const history = useHistory();
  const dispatchBanner = useBannerDispatch();

  const configuration = useConfiguration();
  const [connection, setConnection] = React.useState<ConnectionWithoutType>();
  const [connectionType, setConnectionType] = React.useState<ConnectionTypeFragment>();

  const [saved, setSaved] = React.useState(!!connection);
  const [oauthLoading, setOauthLoading] = React.useState(false);

  const [deletedConnection, setDeletedConnection] = React.useState<ConnectionWithoutType>();
  const [deleteConnectionError, setDeleteConnectionError] = React.useState<string>();
  const [usedBy, setUsedBy] = React.useState<ConnectionUsedBy[]>([]);

  const client = useApolloClient();

  const methods = useForm<ConnectionFormValues>();
  const { formState, handleSubmit, reset, getValues } = methods;
  const { isDirty, dirtyFields } = formState;

  const { user } = useAuth();

  const { loading: connectionTypeLoading } = useQuery(ConnectionTypeDocument, {
    skip: !connectionTypeId,
    variables: { id: connectionTypeId === 'enrichment' ? 'api' : connectionTypeId },
    onCompleted: data => {
      if (!data || !data.connectionType) {
        return;
      }
      setConnectionType(data.connectionType);
      reset({ name: data.connectionType.name, tags: [] });
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } })
  });

  const [getConnection, { loading: connectionLoading }] = useLazyQuery(ConnectionDocument, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      if (!data || !data.connection) {
        return;
      }
      setConnection(data.connection);
      setConnectionType(data.connection.type);
      if (hasItems(data.connection.health.errors)) {
        dispatchBanner({
          type: 'show',
          payload: {
            message: data.connection.health.errors,
            disableDismiss: true,
            theme: 'danger',
            wrapper: wrapperStyles
          }
        });
      }
    },
    onError: error => {
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } });
    }
  });

  const [createConnection, { loading: createLoading }] = useMutation(CreateConnectionDocument, {
    fetchPolicy: 'no-cache',
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ConnectionsDocument }],
    onCompleted: data => {
      if (data?.createConnection?.health?.status === HealthStatus.Error) {
        dispatchBanner({
          type: 'show',
          payload: {
            message: data?.createConnection?.health?.errors ?? 'Failed to create connection',
            wrapper: wrapperStyles
          }
        });
      }
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } })
  });

  const [updateConnection, { loading: mutationLoading }] = useMutation(UpdateConnectionDocument, {
    fetchPolicy: 'no-cache',
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ConnectionsDocument }],
    onCompleted: data => {
      if (data?.updateConnection?.health?.status === HealthStatus.Error) {
        dispatchBanner({
          type: 'show',
          payload: {
            message: data?.updateConnection?.health?.errors ?? 'Failed to update connection',
            wrapper: wrapperStyles
          }
        });
      }
    },
    onError: error =>
      dispatchBanner({ type: 'show', payload: { message: error, wrapper: wrapperStyles } })
  });

  const [deleteConnection, { loading: deleteConnectionLoading }] = useMutation(
    DeleteConnectionDocument,
    {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      update: (cache, { data }) => {
        if (!data || !data.deleteConnection) {
          return;
        }
        if ('error' in (data.deleteConnection as ConnectionErrorResponse)) {
          setDeleteConnectionError((data.deleteConnection as ConnectionDeleteErrorFragment).error);
          setUsedBy(
            (data.deleteConnection as ConnectionDeleteErrorFragment).usedBy as ConnectionUsedBy[]
          );
          return;
        }
        const { id } = data.deleteConnection as ConnectionDeletedSuccessResponse;
        cache.modify({
          fields: {
            connections: (existing: Reference[] = [], helpers) => {
              return existing.filter(ref => helpers.readField('id', ref) !== id);
            }
          }
        });
        cache.evict({ id: cache.identify({ __typename: 'Connection', id }) });
        cache.gc();
        reset();
        history.push(routes.connections);
      }
    }
  );

  const [getAuthURL] = useLazyQuery(ConnectionAuthDocument);

  React.useEffect(() => {
    if (!connection && id) {
      void getConnection({ variables: { id } });
      return;
    }
    if (connection) {
      reset(
        {
          name: connection.name,
          tags: connection.tags || [],
          configuration: cloneDeep(connection.configuration) as Pick<
            ConnectionWithoutType,
            'configuration'
          >
        },
        { keepDirty: connectionType?.id === 'webhook' }
      );
    }
  }, [id, getConnection, connection, reset, connectionType?.id]);

  function fillOAuthURL(connectWindow: Window, authURL: string) {
    setOauthLoading(true);
    connectWindow.location.href = authURL;
    const handlePopupClosedByUser = setInterval(() => {
      if (connectWindow?.closed) {
        setOauthLoading(false);
        clearInterval(handlePopupClosedByUser);
      }
    }, 850);
  }

  // return of onSubmit : Promise<void>
  function onSave(force: boolean) {
    return async (form: ConnectionFormValues) => {
      // open an empty window before starting, if necessary
      let connectWindow: Window | null = null;
      dispatchBanner({ type: 'hide' });

      const { tags, commonVars } = transformFormData(form, connectionType?.configurationSchema);

      if (
        connection &&
        connection.saved &&
        !force &&
        connection.configuration.connect_mode !== 'jwt' &&
        !connectionTypeId &&
        !isDirty &&
        connection.health.status === HealthStatus.Healthy
      ) {
        // The connection is healthy and no changes have been made; simply call
        // updateConnection on the backend so that we re-check the health status
        // and updated any derived settings (e.g., maximum request limits, etc).
        await updateConnection({
          variables: {
            connection: {
              ...commonVars,
              id: connection.id
            },
            tags
          }
        });

        history.push(routes.connections);
        return;
      }

      if (
        connectionType &&
        connectionType.useOAuth &&
        connection?.configuration?.connect_mode !== 'jwt' &&
        (force ||
          !connection ||
          (connection && connection.saved && connection.health.status !== HealthStatus.Healthy))
      ) {
        // always open the window first to avoid browser popup blockers
        connectWindow = window.open(
          '',
          `connect_${connectionType.id}`,
          'width=600,height=600,dependent'
        );
      }

      // If force-reconnecting, get the authURL prior to the update, to not block the OAuth window
      let OAuthURLFilled = false;
      if (connectWindow && connectionType?.useOAuth && connection?.id && force) {
        const { data } = await getAuthURL({
          variables: {
            id,
            update: {
              id: connection.id,
              ...commonVars
            }
          }
        });
        const url = data?.connection?.authorizationURL;
        if (url) {
          fillOAuthURL(connectWindow, url);
          OAuthURLFilled = true;
        }
      }

      let updated: ConnectionWithoutType | null = null;
      try {
        if (connection?.id && connection.id !== NIL) {
          const { data } = await updateConnection({
            variables: {
              connection: {
                ...commonVars,
                id: connection.id
              },
              tags
            }
          });
          if (data) {
            updated = data.updateConnection;
          }
        } else {
          const { data } = await createConnection({
            variables: {
              type: connectionType?.id,
              connection: commonVars,
              tags
            }
          });
          if (data) {
            updated = data.createConnection;
          }
        }

        // If the OAuth url was already filled, wait for user input, do nothing else
        if (OAuthURLFilled) {
          return;
        }

        // see if we need to display the connect dialog
        if (
          connectWindow &&
          (updated?.authURL || connection?.authURL) &&
          updated?.health.status !== HealthStatus.Healthy
        ) {
          fillOAuthURL(connectWindow, updated?.authURL ?? connection?.authURL);
          return;
        }
        if (updated?.saved) {
          connectWindow?.close();
          reset(updated, { keepDirty: false, keepValues: true });
          history.push(routes.connections);
          return;
        }
        if (updated) {
          setConnection(updated);
        }
      } catch (e: unknown) {
        // mutationError will be set, as well, so no need to handle Apollo errors here
        if (e instanceof ApolloError) {
          dispatchBanner({
            type: 'show',
            payload: {
              message: 'An unknown error occurred.',
              wrapper: wrapperStyles
            }
          });
        }
      }
      // either this connection doesn't use OAuth, or there was a problem
      // opening the popup; either way, send them back to the connection list
      connectWindow?.close();
    };
  }

  React.useEffect(() => {
    const onMessage = (
      msg: MessageEvent<{
        event: string;
        id: string;
        connected: boolean;
        error?: string;
      }>
    ) => {
      if (
        new URL(msg.origin).origin !==
          new URL(import.meta.env.VITE_API_URL || document.location.href).origin ||
        msg.data.event !== 'oauthCallback'
      ) {
        // this message wasn't intended for us
        return;
      }

      // display an error message if one exists
      if (msg.data.error) {
        dispatchBanner({
          type: 'show',
          payload: {
            message: (
              <Details
                summary="An error occurred while logging in via OAuth."
                show="View details"
                hide="Hide details"
              >
                <p>{msg.data.error}</p>
              </Details>
            ),
            wrapper: wrapperStyles
          }
        });
      }

      // refresh the connection to show updated errors, token, etc
      void getConnection({ variables: { id: msg.data.id, includeUnsaved: true } });

      setOauthLoading(false);
      setSaved(true);
    };
    const bc = new BroadcastChannel('oauth');
    bc.onmessage = onMessage;
    return () => {
      bc.close();
    };
  }, [connection, saved, oauthLoading, getConnection]);

  async function promiseOptions(field: string, query?: string): Promise<CompletionValue[]> {
    if (!connection) {
      return [];
    }
    try {
      const res = await client.query({
        query: ConnectionParameterCompletionsDocument,
        variables: {
          id: connection.id,
          connectionType: connectionType.id,
          field,
          query,
          params: { name: getValues('name'), ...getValues('configuration') }
        }
      });
      return res.data.connectionParameterCompletions.values;
    } catch (e) {
      const errMessage = (e as ApolloError)?.message || 'An unknown error occurred.';
      dispatchBanner({ type: 'show', payload: { message: errMessage, wrapper: wrapperStyles } });
    }
  }

  async function fetchOptions(field: string, query?: string): Promise<Selectable[]> {
    try {
      const res = await client.query({
        query: ConnectionParameterCompletionsDocument,
        variables: {
          id: connection?.id,
          connectionType: connectionType.id,
          field,
          query,
          params: { name: getValues('name'), ...getValues('configuration') }
        }
      });
      return res.data.connectionParameterCompletions.values as Selectable[];
    } catch (e) {
      const errMessage = (e as ApolloError)?.message || 'An unknown error occurred.';
      dispatchBanner({ type: 'show', payload: { message: errMessage, wrapper: wrapperStyles } });
    }
  }

  function handleDelete() {
    if (!connection) return;
    setDeletedConnection(connection);
    void deleteConnection({ variables: { id: connection.id } });
  }

  function handleDismiss() {
    setDeletedConnection(undefined);
    setDeleteConnectionError('');
    setUsedBy([]);
  }

  React.useEffect(() => {
    if (connectionTypeId) {
      return;
    }
    const escFunction = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        history.push(routes.connections);
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [history, connectionTypeId]);

  const shouldShowAccessControl =
    // If the connection type doesn't use OAuth, always show access control
    !connectionType?.useOAuth ||
    // If the connection type uses OAuth, show access control if the connection is saved
    (connectionType?.useOAuth && (saved || connection?.saved) && connectionType.id !== 'dialpad') ||
    // If the connection type is dialpad and the auth method is not oauth, show access control
    (connectionType.id === 'dialpad' && methods.watch('configuration.auth_method') !== 'oauth') ||
    // If the connection type is dialpad and the auth method is oauth, show access control if the connection is saved
    (connectionType.id === 'dialpad' &&
      methods.watch('configuration.auth_method') == 'oauth' &&
      (saved || connection?.saved));

  return (
    <AclProvider value={connection?.acl}>
      <PageLayout
        loading={connectionLoading || connectionTypeLoading}
        topNavHeading={connectionTypeId ? 'New connection' : 'Edit configuration'}
        topNavActions={
          <>
            <Button
              theme="outline"
              iconEnd="CloseX"
              onClick={() => history.push(routes.connections)}
            >
              Cancel
            </Button>
            <EditPermission>
              <Button
                disabled={oauthLoading}
                loading={mutationLoading || createLoading}
                theme="outline"
                iconEnd="Check"
                onClick={handleSubmit(onSave(false))}
              >
                Save
              </Button>
            </EditPermission>
          </>
        }
      >
        <EditPermission
          fallback={<LacksPermissionBanner message={NO_EDIT_PERMISSION} wrapper={wrapperStyles} />}
        />
        <div className="divide-y divide-gray-300 pb-[max(12rem,35vh)]">
          {connectionType && (
            <>
              <SideBySide hasSectionWrap heading="Connection">
                <div className="flex items-center">
                  <Icon match={connectionType.id} className="mr-2 h-5 w-auto" />
                  <h3 className="text-sm font-medium text-gray-800">{connectionType.name}</h3>
                </div>
              </SideBySide>
              <form
                onSubmit={e => e.preventDefault()}
                className="divide-y divide-gray-300"
                onKeyDown={e => e.key === 'Enter' && e.preventDefault()}
              >
                <FormProvider {...methods}>
                  <ConnectionName />
                  {connectionType.id === 'gsheets' && (
                    <GsheetsConnectionConfig
                      connection={connection}
                      connectionType={connectionType}
                      oauthLoading={oauthLoading}
                      setConnection={setConnection}
                      onSave={onSave}
                    />
                  )}
                  {connectionType.id === 'jira' && (
                    <JiraConnectionConfig connectionType={connectionType} />
                  )}
                  {connectionType.id === 'zendesk_support' && (
                    <ZendeskConnectionConfig
                      connectionType={connectionType}
                      connection={connection}
                      features={user?.organization.features}
                      oauthLoading={oauthLoading}
                      onSave={onSave}
                      saved={saved}
                      promiseOptions={promiseOptions}
                      setConnectionType={setConnectionType}
                    />
                  )}
                  {connectionType.id === 'gong' && (
                    <GongConnectionConfig
                      connectionType={connectionType}
                      connection={connection}
                      features={user?.organization.features}
                      oauthLoading={oauthLoading}
                      onSave={onSave}
                      saved={saved}
                      promiseOptions={promiseOptions}
                      setConnectionType={setConnectionType}
                    />
                  )}
                  {connectionType.id === 'salesloft' && (
                    <SalesloftConnectionConfig
                      connectionType={connectionType}
                      connection={connection}
                      oauthLoading={oauthLoading}
                      onSave={onSave}
                      saved={saved}
                      setConnectionType={setConnectionType}
                    />
                  )}
                  {connectionType.id === 'fbaudience' && (
                    <FacebookAudienceConnectionConfig
                      connection={connection}
                      connectionType={connectionType}
                      features={user?.organization.features}
                      oauthLoading={oauthLoading}
                      onSave={onSave}
                      saved={saved}
                      promiseOptions={promiseOptions}
                      setConnectionType={setConnectionType}
                    />
                  )}
                  {connectionType.id === 'salesforce' && (
                    <SalesforceConnectionConfig
                      connection={connection}
                      connectionType={connectionType}
                      features={user?.organization.features}
                      oauthLoading={oauthLoading}
                      onSave={onSave}
                      saved={saved}
                      promiseOptions={promiseOptions}
                      setConnectionType={setConnectionType}
                    />
                  )}
                  {connectionType.id === 'snowflake' && (
                    <SnowflakeConnectionConfig connectionType={connectionType} />
                  )}
                  {connectionType.id === 'webhook' && (
                    <WebhookConnectionConfig
                      connection={connection}
                      connectionType={connectionType}
                      setConnection={setConnection}
                    />
                  )}
                  {connectionType.id === 'api' && (
                    <APIConnectionConfig connectionType={connectionType} />
                  )}
                  {connectionType.id === 'httpenrichment' && (
                    <EnrichmentConnectionConfig connectionType={connectionType} />
                  )}
                  {connectionType.id === 'csv' && (
                    <CSVConnectionConfig connectionType={connectionType} />
                  )}
                  {connectionType.id === 'dropbox' && (
                    <DropboxConnectionConfig
                      connection={connection}
                      connectionType={connectionType}
                      features={user?.organization.features}
                      oauthLoading={oauthLoading}
                      onSave={onSave}
                      saved={saved}
                      promiseOptions={promiseOptions}
                      setConnectionType={setConnectionType}
                    />
                  )}
                  {connectionType.id === 'azureblob' && (
                    <AzureBlobConnectionConfig
                      connection={connection}
                      connectionType={connectionType}
                      features={user?.organization.features}
                      oauthLoading={oauthLoading}
                      onSave={onSave}
                      saved={saved}
                      promiseOptions={promiseOptions}
                      fetchOptions={fetchOptions}
                      setConnectionType={setConnectionType}
                    />
                  )}
                  {connectionType.id === 'dialpad' && (
                    <DialpadConnectionConfig
                      connectionType={connectionType}
                      connection={connection}
                      oauthLoading={oauthLoading}
                      onSave={onSave}
                      saved={saved}
                      setConnectionType={setConnectionType}
                    />
                  )}
                  {![
                    'gsheets',
                    'jira',
                    'snowflake',
                    'webhook',
                    'api',
                    'csv',
                    'fbaudience',
                    'salesforce',
                    'zendesk_support',
                    'gong',
                    'salesloft',
                    'dropbox',
                    'httpenrichment',
                    'azureblob',
                    'dialpad'
                  ].includes(connectionType.id) &&
                    (connectionType.useOAuth ? (
                      <OauthConnectionConfig
                        connection={connection}
                        connectionType={connectionType}
                        features={user?.organization.features}
                        oauthLoading={oauthLoading}
                        onSave={onSave}
                        saved={saved}
                        promiseOptions={promiseOptions}
                      />
                    ) : (
                      <DefaultConnectionConfig
                        connectionType={connectionType}
                        features={user?.organization.features}
                        promiseOptions={promiseOptions}
                        fetchOptions={fetchOptions}
                      />
                    ))}

                  <FeatureFlag feature="datalite-connection">
                    {connectionType.operations.includes(Operation.PolytomicConnection) && (
                      <ConnectionObjectCachingOption
                        connection={connection}
                        connectionType={connectionType}
                      />
                    )}
                  </FeatureFlag>
                  {shouldShowAccessControl && (
                    <AccessControlWrap>
                      <AccessControlInForm resourceType={ResourceType.Connection} />
                    </AccessControlWrap>
                  )}
                  <ConnectionIPWhitelist
                    hidden={
                      configuration.state.on_premises ||
                      !connectionType.operations.includes(Operation.RequiresIpWhitelist)
                    }
                  />
                </FormProvider>
              </form>
            </>
          )}
          {!connectionTypeId && connection && connectionType && (
            <ConfigTriggerDialog
              isDelete={true}
              cta="Delete connection"
              description="Deleting this connection will cause failure of any sync that depends on it."
              handleAction={handleDelete}
              handleDismiss={handleDismiss}
              preventToggle={['action']}
              hideAction={!!(deleteConnectionError && deletedConnection && hasItems(usedBy))}
              loading={deleteConnectionLoading}
              dismissText={
                deleteConnectionError && deletedConnection && hasItems(usedBy) ? 'Close' : 'Cancel'
              }
            >
              {deleteConnectionError && deletedConnection && hasItems(usedBy) ? (
                <ConnectionInUse
                  connection={deletedConnection}
                  connectionType={connectionType}
                  error={deleteConnectionError}
                  usedBy={usedBy}
                />
              ) : (
                <>
                  <p className="mb-4 flex items-center text-sm font-medium text-gray-800">
                    <Icon match={connectionType.id} className="mr-2 h-5" />
                    {connection?.name}
                  </p>
                  <p className="text-sm text-gray-600 [max-width:65ch]">
                    Deleting this connection will cause failure of any sync that depends on it. You
                    might want to amend those syncs or models before you delete this connection.
                  </p>
                </>
              )}
            </ConfigTriggerDialog>
          )}
        </div>
        <PromptUnsaved when={isDirty && hasItems(Object.keys(dirtyFields))} />
      </PageLayout>
    </AclProvider>
  );
}
