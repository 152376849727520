import { RowSelectionState, functionalUpdate } from '@tanstack/react-table';
import { debounce } from 'lodash';
import { Resizable } from 're-resizable';
import { useEffect, useMemo, useState } from 'react';
import { Button, Icon, Search } from '~/components';
import { ColumnDef, DataTable } from '~/components/v3';
import { SelectionList } from '~/components/v3/SelectionList';
import { Sheet, SheetContent } from '~/components/v3/Sheet';
import { searchByNameAndId } from '~/utils';
import { IBulkNamespace, IBulkSchema, getEntitiesFromPath } from '../components/BulkNamespaceUtil';
import { useEstimatedRowSize } from '~/hooks';

interface BulkFieldPickerDrawerProps {
  namespaces: IBulkNamespace[];
  open: boolean;
  onDismiss: (field?: IBulkSchema) => void;
  selectedPath: string;
}

export function BulkSchemaPickerDrawer({
  open,
  namespaces,
  onDismiss,
  selectedPath
}: BulkFieldPickerDrawerProps) {
  const [search, setSearch] = useState<string>();
  const [width, setWidth] = useState(224);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const isNamespaceRoot = useMemo(
    () => namespaces?.length === 1 && !namespaces[0].id,
    [namespaces]
  );

  const [selectedNamespace, setSelectedNamespace] = useState<IBulkNamespace>();

  const handleSearchChange = (value: string) => {
    setSearch(value);
  };

  const debouncedSearchChange = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, [handleSearchChange]);

  const handleRowSelectionChange = (selection: RowSelectionState) => {
    setRowSelection(selection);
    const { namespace } = getEntitiesFromPath(namespaces, Object.keys(selection)[0]);
    setSelectedNamespace(namespace);
  };

  useEffect(() => {
    if (selectedNamespace) {
      const { namespace } = getEntitiesFromPath(namespaces, selectedNamespace.path);
      if (namespace) {
        setSelectedNamespace(namespace);
      }
    }

    if (namespaces.length === 1) {
      setSelectedNamespace(namespaces[0]);
    }
  }, [namespaces]);

  useEffect(() => {
    if (selectedPath) {
      const { namespace } = getEntitiesFromPath(namespaces, selectedPath);
      handleRowSelectionChange({ ...rowSelection, [namespace?.path]: true });
    }
  }, [selectedPath]);

  const columns: ColumnDef<IBulkSchema>[] = [
    {
      id: 'name',
      accessorKey: 'name',
      header: 'Name'
    }
  ];

  const reset = () => {
    setSearch('');
    setRowSelection({});
    setSelectedNamespace(undefined);
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      onDismiss();
      reset();
    }
  };

  const handleFieldSelection = (schema: IBulkSchema) => {
    onDismiss(schema);
    reset();
  };

  const estimatedRowSize = useEstimatedRowSize({ small: 48, large: 52.5 });

  return (
    <Sheet open={open} onOpenChange={handleOpenChange} modal={false}>
      <SheetContent
        container={document.getElementsByTagName('main')[0]}
        storageKey="bulkSyncSchemaPickerDrawer"
        header={
          <>
            <div className="flex flex-1 flex-wrap items-center">
              {/* TODO */}
              <h2 className="text-base font-medium">Select object</h2>
            </div>
            <div className="flex flex-1 justify-center">
              <Search
                className="font-normal"
                wrapperStyles="h-8 w-80"
                placeholder={`Search...`}
                defaultValue={search}
                onChange={debouncedSearchChange}
                onReset={() => setSearch('')}
              />
            </div>
            <div className="flex flex-1 items-center justify-end space-x-2">
              <Button onClick={() => handleOpenChange(false)} theme="primary">
                Cancel
              </Button>
            </div>
          </>
        }
      >
        <div className="flex h-full w-full items-start gap-4 self-stretch overflow-y-auto overflow-x-clip">
          {!isNamespaceRoot && (
            <div className="flex h-full self-stretch overflow-y-clip">
              <Resizable
                handleClasses={{
                  top: 'pointer-events-none',
                  bottom: 'pointer-events-none',
                  left: 'pointer-events-none',
                  topRight: 'pointer-events-none',
                  bottomRight: 'pointer-events-none',
                  bottomLeft: 'pointer-events-none',
                  topLeft: 'pointer-events-none',
                  right: `
                      h-full flex flex-col items-center justify-center
                      bg-transparent hover:bg-gray-200 active:bg-gray-300 transition
                      absolute !-right-2.5 text-gray-400 hover:text-gray-500 active:text-gray-600
                      rounded !w-[5px]
                    `
                }}
                handleComponent={{ right: <Icon name="ResizeHandleV" size="lg" /> }}
                minHeight="100%"
                size={{ width, height: '100%' }}
                onResizeStop={(e, direction, ref, d) => {
                  setWidth(w => w + d.width);
                }}
              >
                <SelectionList<IBulkNamespace | IBulkSchema>
                  data={namespaces}
                  getName={row => row.name}
                  getRowId={row => row.path}
                  rowSelection={rowSelection}
                  onRowSelectionChange={fn =>
                    handleRowSelectionChange(functionalUpdate(fn, rowSelection))
                  }
                  globalFilter={row => !search || searchByNameAndId(row.original, search)}
                  onGlobalFilterChange={setSearch}
                  maxLeafRowFilterDepth={2}
                />
              </Resizable>
            </div>
          )}
          <DataTable
            data={
              isNamespaceRoot
                ? namespaces[0].schemas
                : selectedNamespace
                  ? selectedNamespace.schemas
                  : []
            }
            columns={columns}
            showLoadingWhenRowsExist={true}
            getRowId={row => row.id}
            // Filtering
            globalFilter={search}
            onGlobalFilterChange={setSearch}
            classNames={{ wrapper: 'w-full', row: 'cursor-pointer' }}
            emptyMessage={selectedNamespace ? 'No results.' : `Select a namespace on the left.`}
            onRowClick={handleFieldSelection}
            isRowActive={row => row.path === selectedPath}
            estimateSize={() => estimatedRowSize}
          />
        </div>
      </SheetContent>
    </Sheet>
  );
}
