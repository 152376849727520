import React, { useMemo } from 'react';
import { Dialog } from '~/components/v3';
import { Button, TableWrap } from '~/components/form-components';
import { buildDiff } from '~/utils/v2/sql-diff';
import { ActivityLogItem } from './ActivityTypes/ActivityTypes';
import dayjs from 'dayjs';

export type ActivityLogSqlDiffDialogProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  log?: ActivityLogItem;
};

const ActivityLogSqlDiffDialog = ({ show, setShow, log }: ActivityLogSqlDiffDialogProps) => {
  const formattedTimestamp = useMemo(() => {
    const ts = log?.performedAt;
    return `${dayjs(ts)?.format('MMMM DD, YYYY')} at ${dayjs(ts).format('hh:mm a')}`;
  }, [log]);

  return (
    <Dialog
      show={show}
      size="lg"
      heading="Activity log details"
      onDismiss={() => setShow(false)}
      actions={<Button onClick={() => setShow(false)}>Close</Button>}
    >
      {!!log && (
        <>
          <div className="mb-4 flex flex-row space-x-1">
            <span className="text-sm font-medium capitalize text-gray-800">{log.label}</span>
            <span className="text-sm text-gray-500">·</span>
            <span className="text-sm text-gray-500">
              {log.performedBy.displayName}, {formattedTimestamp}
            </span>
          </div>
          <TableWrap className="max-h-[400px] overflow-auto font-mono text-sm">
            <table className="w-full min-w-full whitespace-pre-wrap align-text-top">
              <tbody>
                {buildDiff(log).lineDiffs.map((item, idx) => {
                  if (item.oldlineContent === item.newlineContent) {
                    return (
                      <tr key={idx} className={'min-w-full align-text-top'}>
                        <td className={'w-8 bg-gray-100 pt-0.5 text-right text-gray-400'}>
                          {item.lineNumber}
                        </td>
                        <td className={'w-8 bg-gray-100 pt-0.5 pr-2 text-right text-gray-400'}>
                          {item.lineNumber}
                        </td>
                        <td className={'w-8 bg-gray-100 pt-0.5 pr-2 text-right text-gray-400'}></td>
                        <td className={'pt-0.5 pl-2 text-left text-gray-400'}>
                          {item.oldlineContent}
                        </td>
                      </tr>
                    );
                  }
                  if (item.oldlineContent === null && item.newlineContent !== null) {
                    return (
                      <tr key={idx} className={'min-w-full align-text-top'}>
                        <td className={'w-8 bg-emerald-100 pt-0.5 text-right text-emerald-600'}>
                          {/* {item.lineNumber} */}
                        </td>
                        <td
                          className={'w-8 bg-emerald-100 pt-0.5 pr-2 text-right text-emerald-600'}
                        >
                          {item.lineNumber}
                        </td>
                        <td
                          className={'w-8 bg-emerald-100 pt-0.5 pr-2 text-right text-emerald-600'}
                        >
                          +
                        </td>
                        <td className={'bg-emerald-100 pt-0.5 pl-2 text-left text-emerald-600'}>
                          {item.newlineContent}
                        </td>
                      </tr>
                    );
                  }
                  if (item.oldlineContent !== null && item.newlineContent === null) {
                    return (
                      <tr key={idx} className={'min-w-full align-text-top'}>
                        <td className={'w-8 bg-red-100 pt-0.5 text-right text-red-500'}>
                          {item.lineNumber}
                        </td>
                        <td className={'w-8 bg-red-100 pt-0.5 pr-2 text-right text-red-500'}>
                          {/* {item.lineNumber} */}
                        </td>
                        <td className={'w-8 bg-red-100 pt-0.5 pr-2 text-right text-red-500'}>-</td>
                        <td className={'bg-red-100 pt-0.5 pl-2 text-left text-red-500'}>
                          {item.oldlineContent}
                        </td>
                      </tr>
                    );
                  }
                  if (item.oldlineContent !== item.newlineContent) {
                    return (
                      <React.Fragment key={idx}>
                        <tr className={'min-w-full align-text-top'}>
                          <td className={'w-8 bg-red-100 pt-0.5 text-right text-red-500'}>
                            {item.lineNumber}
                          </td>
                          <td className={'w-8 bg-red-100 pt-0.5 pr-2 text-right text-red-500'}>
                            {/* {item.lineNumber} */}
                          </td>
                          <td className={'w-8 bg-red-100 pt-0.5 pr-2 text-right text-red-500'}>
                            -
                          </td>
                          <td className={'bg-red-100 pt-0.5  pl-2 text-left text-red-500'}>
                            {item.oldlineContent}
                          </td>
                        </tr>
                        <tr key={idx} className={'min-w-full align-text-top'}>
                          <td className={'w-8 bg-emerald-100 pt-0.5 text-right text-emerald-600'}>
                            {/* {item.lineNumber} */}
                          </td>
                          <td
                            className={'w-8 bg-emerald-100 pt-0.5 pr-2 text-right text-emerald-600'}
                          >
                            {item.lineNumber}
                          </td>
                          <td
                            className={'w-8 bg-emerald-100 pt-0.5 pr-2 text-right text-emerald-600'}
                          >
                            +
                          </td>
                          <td className={'bg-emerald-100 pt-0.5  pl-2 text-left text-emerald-600'}>
                            {item.newlineContent}
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  }
                })}
              </tbody>
            </table>
          </TableWrap>
        </>
      )}
    </Dialog>
  );
};

export default ActivityLogSqlDiffDialog;
