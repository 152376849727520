import { useState } from 'react';
import { z } from 'zod';
import { Tooltip } from '~/components';
import { Button } from '~/components/form-components';
import { EditableFieldsetFragment } from '~/generated/graphql';
import { fieldTypeIconName } from '~/utils';
import { buildDiff } from '~/utils/v2/sql-diff';
import { Icon } from '../../../../Icon';
import Marker from '../ActivityLogMarker';
import ActivityLogSqlDiffDialog from '../ActivityLogSqlDiffDialog';
import { ActivityType, Category, renderers, schemas } from './ActivityTypes';

// Categories are used for filtering
export const modelCategories: Record<string, Category> = {
  modelConfig: { label: 'Model config', value: 'modelConfig' },
  modelFields: { label: 'Model fields', value: 'modelFields' },
  modelLabels: { label: 'Model labels', value: 'modelLabels' },
  modelName: { label: 'Model name', value: 'modelName' },
  sqlQuery: {
    label: ({ context }) =>
      (context as EditableFieldsetFragment).connection?.type?.id === 'salesforce'
        ? 'SOQL query'
        : 'SQL query',
    value: 'sqlQuery',
    shouldRender: ({ context }) =>
      ((context as EditableFieldsetFragment)?.configuration as any)?.query !== undefined
  },
  trackingField: {
    label: 'Tracking field',
    value: 'trackingField',
    shouldRender: ({ context }) =>
      ((context as EditableFieldsetFragment)?.configuration as any)?.trackingColumns !== undefined
  },
  joinToModels: { label: 'Join to other models', value: 'joinToModels' },
  permissions: { label: 'Permissions', value: 'permissions' },
  pagination: { label: 'Pagination', value: 'pagination' },
  enrichment: { label: 'Enrichment', value: 'enrichment' }
};

export const modelActivityTypes: Record<string, ActivityType> = {
  'field.created': {
    category: modelCategories.modelFields,
    label: 'Model fields',
    render: ({ log }) => {
      const l = schemas.newStr.parse(log);
      return <Marker type="plus">{l.new.str}</Marker>;
    }
  },
  'field.deleted': {
    category: modelCategories.modelFields,
    label: 'Model fields',
    render: ({ log }) => {
      const l = schemas.oldStr.parse(log);
      return <Marker type="minus">{l.old.str}</Marker>;
    }
  },
  'field.label': {
    category: modelCategories.modelFields,
    label: ({ log }) => `Field label: ${log.context?.source_name}`,
    render: renderers.strDiff
  },
  'field.primary_key': {
    category: modelCategories.modelFields,
    label: 'Unique identifier',
    render: ({ log }) => {
      const l = schemas.fieldContext.and(schemas.newBool).and(schemas.oldBool).parse(log);
      return (
        <>
          {l.old.val !== false && <Marker type="minus">{l.context.source_name}</Marker>}
          {l.new.val !== false && <Marker type="plus">{l.context.source_name}</Marker>}
        </>
      );
    }
  },
  'field.published': {
    category: modelCategories.modelFields,
    label: 'Model fields',
    render: ({ log }) => {
      // Todo - old value is unused, new value is not present in case of remove
      const l = z
        .intersection(
          schemas.fieldContext,
          z.object({ new: z.object({ val: z.boolean() }).or(z.null()) })
        )
        .parse(log);
      return (
        <>
          {l.new?.val !== true && <Marker type="minus">{l.context.source_name}</Marker>}
          {l.new?.val == true && <Marker type="plus">{l.context.source_name}</Marker>}
        </>
      );
    }
  },
  'field.relationshipupdate.join.created': {
    category: modelCategories.joinToModels,
    label: 'Join to other models',
    render: ({ log }) => {
      const l = schemas.newRelationship.parse(log);
      return (
        <Marker type="plus">
          <span>{l.new.fieldName}</span>
          <span className="text-gray-500"> joins to </span>
          <Tooltip content={l.new.foreignFieldsetName} offset={[0, 4]}>
            <div className="inline-block cursor-default">
              <Icon match={l.new.foreignFieldsetType} className="inline-flex" />
              <span> {l.new.foreignFieldName}</span>
            </div>
          </Tooltip>
        </Marker>
      );
    }
  },
  'field.relationshipupdate.join.deleted': {
    category: modelCategories.joinToModels,
    label: 'Join to other models',
    render: ({ log }) => {
      const l = schemas.oldRelationship.parse(log);
      return (
        <Marker type="minus">
          <span>{l.old.fieldName}</span>
          <span className="text-gray-500"> joins to </span>
          <Tooltip content={l.old.foreignFieldsetName} offset={[0, 4]}>
            <div className="inline-block cursor-default">
              <Icon match={l.old.foreignFieldsetType} className="inline-flex" />
              <span> {l.old.foreignFieldName}</span>
            </div>
          </Tooltip>
        </Marker>
      );
    }
  },
  'model.configuration.aggregation_changed': {
    category: modelCategories.modelConfig,
    label: 'Model aggregation',
    render: renderers.strDiff
  },
  'model.configuration.base_changed': {
    category: modelCategories.modelConfig,
    label: 'Base',
    render: renderers.strDiff
  },
  'model.configuration.catalog_changed': {
    category: modelCategories.modelConfig,
    label: 'Catalog',
    render: renderers.strDiff
  },
  'model.configuration.collection_changed': {
    category: modelCategories.modelConfig,
    label: 'Collection',
    render: renderers.strDiff
  },
  'model.configuration.database_changed': {
    category: modelCategories.modelConfig,
    label: 'Database',
    render: renderers.strDiff
  },
  'model.configuration.dataset_changed': {
    category: modelCategories.modelConfig,
    label: 'Dataset',
    render: renderers.strDiff
  },
  'model.configuration.entity_changed': {
    category: modelCategories.modelConfig,
    label: 'Entity',
    render: renderers.strDiff
  },
  'model.configuration.delim_changed': {
    category: modelCategories.modelConfig,
    label: 'Model delimiter',
    render: renderers.strDiff
  },
  'model.configuration.object_changed': {
    category: modelCategories.modelConfig,
    label: 'Model object',
    render: renderers.strDiff
  },
  'model.configuration.project_id_changed': {
    category: modelCategories.modelConfig,
    label: 'Project ID',
    render: renderers.strDiff
  },
  'model.configuration.path_changed': {
    category: modelCategories.modelConfig,
    label: 'Model path',
    render: renderers.strDiff
  },
  'model.configuration.query_changed': {
    category: modelCategories.sqlQuery,
    label: ({ context }) =>
      (context as EditableFieldsetFragment)?.connection?.type?.id === 'salesforce'
        ? 'SOQL query'
        : 'SQL query',
    render: ({ log }) => {
      const [show, setShow] = useState<boolean>();
      const l = z.intersection(schemas.newStr, schemas.oldStr).parse(log);
      const sqlDiff = buildDiff({ ...log, ...l });
      return (
        <>
          {sqlDiff.linesAdded > 0 && (
            <Marker type="plus">
              {sqlDiff.linesAdded} {sqlDiff.linesAdded > 1 ? 'lines' : 'line'}
            </Marker>
          )}
          {sqlDiff.linesRemoved > 0 && (
            <Marker type="minus">
              {sqlDiff.linesRemoved} {sqlDiff.linesRemoved > 1 ? 'lines' : 'line'}
            </Marker>
          )}
          <Button size="mini" onClick={() => setShow(true)}>
            Details
          </Button>
          <ActivityLogSqlDiffDialog show={show} setShow={setShow} log={log} />
        </>
      );
    }
  },
  'model.configuration.schema_changed': {
    category: modelCategories.modelConfig,
    label: 'Schema',
    render: renderers.strDiff
  },
  'model.configuration.table_changed': {
    category: modelCategories.modelConfig,
    label: 'Table',
    render: renderers.strDiff
  },
  'model.configuration.tracking_columns_updated': {
    category: modelCategories.trackingField,
    label: 'Tracking field',
    render: ({ log }) => {
      const l = z.intersection(schemas.newArray, schemas.oldArray).parse(log);
      return (
        <>
          {l.old?.arr?.length > 0 && <Marker type="minus">{l.old.arr.join(', ')}</Marker>}
          {l.new?.arr?.length > 0 && <Marker type="plus">{l.new.arr.join(', ')}</Marker>}
        </>
      );
    }
  },
  'model.name_change': {
    category: modelCategories.modelName,
    label: 'Model name',
    render: renderers.strDiff
  },
  'model.created': {
    // We render a group label and no children for created
    category: null,
    label: 'Model created',
    render: () => null
  },
  'model.deleted': {
    category: null,
    label: 'Model deleted',
    render: () => null
  },
  'model.configuration.view_changed': {
    category: modelCategories.modelConfig,
    label: 'View',
    render: renderers.strDiff
  },
  'model.configuration.includes_deleted': {
    category: modelCategories.modelConfig,
    label: 'Includes deleted',
    render: ({ log }) => {
      // todo missing context.source_name from backend
      const l = schemas.fieldContext.and(schemas.newBool).and(schemas.oldBool).parse(log);
      return (
        <>
          <Marker type="minus">{l.old.val ? 'true' : 'false'}</Marker>
          <Marker type="plus">{l.new.val ? 'true' : 'false'}</Marker>
        </>
      );
    }
  },
  'model.configuration.recordpath_changed': {
    category: modelCategories.modelConfig,
    label: 'Record path',
    render: renderers.strDiff
  },
  'model.configuration.sheet_changed': {
    category: modelCategories.modelConfig,
    label: 'Sheet',
    render: renderers.strDiff
  },
  'model.configuration.source_changed': {
    category: modelCategories.modelConfig,
    label: 'Source',
    render: renderers.strDiff
  },
  'model.configuration.survey_changed': {
    category: modelCategories.modelConfig,
    label: 'Survey',
    render: renderers.strDiff
  },
  'model.configuration.topics_changed': {
    category: modelCategories.modelConfig,
    label: 'Topics',
    render: renderers.arrayDiff
  },
  'model.label.created': {
    category: modelCategories.modelLabels,
    label: 'Model labels',
    render: ({ log }) => {
      const l = schemas.newStr.parse(log);
      return <Marker type="plus">{l.new.str}</Marker>;
    }
  },
  'model.label.deleted': {
    category: modelCategories.modelLabels,
    label: 'Model labels',
    render: ({ log }) => {
      const l = schemas.oldStr.parse(log);
      return <Marker type="minus">{l.old.str}</Marker>;
    }
  },
  'model.configuration.mode_changed': {
    category: modelCategories.modelConfig,
    label: 'Mode changed',
    render: renderers.strDiff
  },
  'model.configuration.parameters_changed': {
    category: modelCategories.modelConfig,
    label: 'Query string parameters',
    render: renderers.arrayDiff
  },
  'model.configuration.headers_changed': {
    category: modelCategories.modelConfig,
    label: 'Headers',
    render: renderers.arrayDiff
  },
  'model.configuration.pagination.mode_changed': {
    category: modelCategories.modelConfig,
    label: 'Pagination mode changed',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.parameter_definition.name_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: Token send-as name',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.parameter_definition.location_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: Token location',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.token_transformation_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: Token transformation',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.terminate_on_empty': {
    category: modelCategories.pagination,
    label: 'Pagination: Terminate on empty',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.more_results_path_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: "More results" path',
    render: renderers.strDiff
  },
  'model.configuration.pagination.token.token_path_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: Token path',
    render: renderers.strDiff
  },
  'model.configuration.pagination.next_page.next_page_location': {
    category: modelCategories.pagination,
    label: 'Pagination: Next page path',
    render: renderers.strDiff
  },
  'model.configuration.pagination.next_page.next_page_path': {
    category: modelCategories.pagination,
    label: 'Pagination: Next page path',
    render: renderers.strDiff
  },
  'model.configuration.pagination.offset.limit_parameter_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: Limit',
    render: renderers.strDiff
  },
  'model.configuration.pagination.offset.offset_parameter_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: Offset paramater',
    render: renderers.strDiff
  },
  'model.configuration.pagination.offset.page_size_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: Page size',
    render: renderers.strDiff
  },
  'model.configuration.pagination.offset.record_limit_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: Record limit',
    render: renderers.strDiff
  },
  'model.configuration.pagination.offset.stops_on_partial_page': {
    category: modelCategories.pagination,
    label: 'Pagination: Stop on partial page',
    render: renderers.strDiff
  },
  'model.configuration.pagination.sequential.parameter_name_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: Parameter name',
    render: renderers.strDiff
  },
  'model.configuration.pagination.sequential.page_parameter_name_changed': {
    category: modelCategories.pagination,
    label: 'Pagination: Page parameter name',
    render: renderers.strDiff
  },
  'permissions_tag.added': {
    category: modelCategories.permissions,
    label: 'Permission added',
    render: ({ log }) => {
      const l = schemas.newStr.parse(log);
      return <Marker type="plus">{l.new.str}</Marker>;
    }
  },
  'permissions_tag.removed': {
    category: modelCategories.permissions,
    label: 'Permission removed',
    render: ({ log }) => {
      const l = schemas.oldStr.parse(log);
      return <Marker type="minus">{l.old.str}</Marker>;
    }
  },
  'field.type_changed': {
    category: modelCategories.modelFields,
    label: ({ log }) => `Field type changed: ${log.context?.source_name}`,
    render: ({ log }) => {
      const l = z.intersection(schemas.newStr, schemas.oldStr).parse(log);
      return (
        <>
          <Marker type="minus">
            <div className="flex items-center space-x-1.5">
              <Icon name={fieldTypeIconName(l.old.str)} className="h-5 w-5 text-gray-500" />
              <span className="text-gray-800">{l.old.str}</span>
            </div>
          </Marker>
          <Marker type="plus">
            <div className="flex items-center space-x-1.5">
              <Icon name={fieldTypeIconName(l.new.str)} className="h-5 w-5 text-gray-500" />
              <span className="text-gray-800">{l.new.str}</span>
            </div>
          </Marker>
        </>
      );
    }
  },
  'model.configuration.base.updated': {
    category: modelCategories.modelConfig,
    label: 'Base',
    render: renderers.valueLabelDiff
  },
  'model.configuration.table.updated': {
    category: modelCategories.modelConfig,
    label: 'Table',
    render: renderers.valueLabelDiff
  },
  'model.configuration.view.updated': {
    category: modelCategories.modelConfig,
    label: 'View',
    render: renderers.valueLabelDiff
  },
  'model.enrichment.configuration.object_changed': {
    category: modelCategories.enrichment,
    label: 'Enrichment object',
    render: renderers.strDiff
  },
  'model.enrichment.created': {
    category: modelCategories.enrichment,
    label: 'Enrichment',
    render: renderers.strDiff
  },
  'model.enrichment.deleted': {
    category: modelCategories.enrichment,
    label: 'Enrichment',
    render: renderers.strDiff
  },
  'model.enrichment.field.label': {
    category: modelCategories.enrichment,
    label: 'Enrichment fields',
    render: renderers.strDiff
  },
  'model.enrichment.field.published': {
    category: modelCategories.enrichment,
    label: 'Enrichment fields',
    render: ({ log }) => {
      // Todo - old value is unused, new value is not present in case of remove
      const l = z
        .intersection(
          schemas.fieldContext,
          z.object({ new: z.object({ val: z.boolean() }).or(z.null()) })
        )
        .parse(log);
      return (
        <>
          {l.new?.val !== true && <Marker type="minus">{l.context.source_name}</Marker>}
          {l.new?.val == true && <Marker type="plus">{l.context.source_name}</Marker>}
        </>
      );
    }
  },
  'model.enrichment.mapping_added': {
    category: modelCategories.enrichment,
    label: 'Enrichment mapping',
    render: ({ log }) => {
      const l = schemas.newPair.parse(log);
      return <Marker type="plus">{`${l.new.elem1} → ${l.new.elem2}`}</Marker>;
    }
  },
  'model.enrichment.mapping_removed': {
    category: modelCategories.enrichment,
    label: 'Enrichment mapping',
    render: ({ log }) => {
      const l = schemas.oldPair.parse(log);
      return <Marker type="minus">{`${l.old.elem1} → ${l.old.elem2}`}</Marker>;
    }
  },
  'model.enrichment.mapping_changed': {
    category: modelCategories.enrichment,
    label: 'Enrichment mapping',
    render: renderers.pairDiff
  },
  'model.configuration.static_list.updated': {
    category: modelCategories.modelConfig,
    label: 'Static list',
    render: renderers.strDiff
  }
};
